import { KeyboardArrowDown, Notes } from '@mui/icons-material'
import { Accordion, AccordionSummary, Box, Typography, AccordionDetails } from '@mui/material'
import { ResourceLayers } from '../../../assets/svgs/ResourceLayers'
import { RatingRadio } from '../../customComponents/RatingRadio'
import { TextArea } from '../../customComponents/TextArea'
import { BlueButton } from '../../customComponents/buttons/BlueButton'
import { ProfileImage } from '../../customComponents/user/ProfileImage'
import { type CETASnapshotIndicator, type CETAReconcileAssessmentIndicator } from '../../../models/CETAReconcileAssessment'
import { useState } from 'react'
import { SaveReconcileIndicator } from '../../../services/CETAClassroomService'
import { type CETAReconcileIndicatorRequest } from '../../../models/CETAReconcileIndicatorRequest'
import { toast } from 'react-toastify'

interface Props {
  isSelected: boolean
  indicator: CETAReconcileAssessmentIndicator
  readOnly: boolean
  highlightIndicators: boolean
  onSaveIndicatorRating: (indicator: CETAReconcileIndicatorRequest) => void
  onClick: () => void
}

export const IndicatorReview = (props: Props): JSX.Element => {
  const [notes, setNotes] = useState<string>(props.indicator.notes)
  const [rating, setRating] = useState<number | undefined>(props.indicator.rating)
  const [showNotes, setShowNotes] = useState<boolean>(false)

  const confirmResponse = async (indicatorId: number): Promise<void> => {
    if (rating == null) {
      toast.error('Please select a rating')
      return
    }

    const indicatorRequest: CETAReconcileIndicatorRequest = { id: indicatorId, rating, notes }
    await SaveReconcileIndicator(indicatorRequest)
    toast.success('Response saved')
    props.onSaveIndicatorRating(indicatorRequest)
    props.onClick()
  }

  const updateRating = (value: number): void => {
    setRating(value)
    if (!props.isSelected) {
      props.onClick()
    }
  }

  const ratingAverage = (submittedIndicators: CETASnapshotIndicator[]): number => {
    const completedIndicators = submittedIndicators.filter(i => i.rating != null)

    if (completedIndicators.length === 0) {
      return 0
    }

    const total = completedIndicators.reduce((acc, curr) => acc + (curr.rating ?? 0), 0)
    return Math.round(total / completedIndicators.length * 1000) / 1000
  }

  return <Accordion
    expanded={props.isSelected}
    data-testid={`indicator-${props.indicator.id}`}
    sx={{
      marginBottom: '.5em',
      backgroundColor: props.isSelected ? '#88CAEA80' : '#F3F3F399',
      border: props.highlightIndicators && props.indicator.rating == null ? '1px solid red' : '1px solid #CBC8CC',
      borderRadius: '6px'
    }}
  >
    <AccordionSummary expandIcon={<KeyboardArrowDown />} onClick={props.onClick} role='none presentation' aria-expanded={undefined}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Box display='flex' alignItems='center'>
          <ResourceLayers />
          <Typography variant='subtitle1' ml='.5em'>{props.indicator.name}</Typography>
        </Box>

        <RatingRadio
          initialValue={rating}
          readOnly={props.readOnly}
          onValueSelected={val => { updateRating(val) }}
          onClick={(e) => { e.stopPropagation() }}
        />
      </Box>
    </AccordionSummary>

    <AccordionDetails>
      <Box sx={{ backgroundColor: '#DCF3FE', p: '1em', borderRadius: '6px' }}>
        <Box sx={{ display: 'flex' }}>
          {props.indicator.submittedIndicators.map((submittedIndicator, index) => (
            <Box key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mr: '.5em' }}>
              <ProfileImage
                numberOfLetters={2}
                name={submittedIndicator.firstName.charAt(0) + submittedIndicator.lastName.charAt(0)}
                imageKey={submittedIndicator.imageKey}
              />

              <Typography data-testid={`submittedIndicatorRating-${submittedIndicator.lastName}`}>{submittedIndicator.rating ?? '-'}</Typography>
            </Box>
          ))}

          <Typography alignContent='center' mx='2em' data-testid='averageRating'>Avg: {ratingAverage(props.indicator.submittedIndicators)}</Typography>

          <Notes sx={{ alignSelf: 'center', cursor: 'pointer' }} onClick={() => { setShowNotes(!showNotes) }} data-testid='showNotes' />
        </Box>

        {showNotes && props.indicator.submittedIndicators.map((submittedIndicator, index) => (
          <Box key={index}>
            {submittedIndicator.notes !== '' &&
              <Box sx={{ backgroundColor: 'white', mt: '.5em', p: '.5em', borderRadius: '6px' }} data-testid='submittedNotes'>
                <Typography fontWeight='600'>{submittedIndicator.firstName} {submittedIndicator.lastName} -</Typography>
                <Typography>{submittedIndicator.notes}</Typography>
              </Box>
            }
          </Box>
        ))}

        {showNotes && props.indicator.submittedIndicators.every(i => i.notes === '') &&
          <Typography sx={{ backgroundColor: 'white', mt: '.5em', p: '.5em', borderRadius: '6px' }}>
            No notes were provided
          </Typography>
        }
      </Box>

      <Typography variant='body1' my='1em'>{props.indicator.description}</Typography>

      <Box sx={{ display: 'flex' }}>
        <TextArea
          initialValue={notes}
          readOnly={props.readOnly}
          name='indicatorNotes'
          placeholder='Final Indicator notes go here'
          inputSx={{ backgroundColor: 'white' }}
          sx={{ width: '100%' }}
          onBlur={(e) => { setNotes(e.target.value) }}
        />

        {!props.readOnly &&
          <BlueButton
            onClick={async () => { await confirmResponse(props.indicator.id) }}
            dataTestId='confirmResponse'
            sx={{ height: '4em', width: '14em', ml: '1em', alignSelf: 'center' }}
          >
            Confirm Response
          </BlueButton>
        }
      </Box>
    </AccordionDetails>
  </Accordion>
}
