import { Box, CircularProgress, type SelectChangeEvent, Typography } from '@mui/material'
import { type GoalOwner, type CETAGoal, type CETAPlanAction } from '../../../../models/CETAPlanSectionModel'
import { Modal } from '../../../customComponents/Modal'
import { RatingRadio } from '../../../customComponents/RatingRadio'
import { SelectWithLabel } from '../../../customComponents/SelectWithLabel'
import { TextArea } from '../../../customComponents/TextArea'
import { AddCircle } from '@mui/icons-material'
import { useEffect, useRef, useState } from 'react'
import { GetCETATeam, LockIndicatorGoal, LockSectionGoal } from '../../../../services/CETAClassroomService'
import { toast } from 'react-toastify'
import { BannerModal } from '../../../customComponents/BannerModal'
import { DatePickerWithLabel } from '../../../customComponents/DatePickerWithLabel'
import { CETAGoalActionDetails } from './CETAGoalActionDetails'
import { AddCETAGoalActionModal } from './AddCETAGoalActionModal'

interface Props {
  classroomId: string
  sectionId?: number
  indicatorId?: number
  name: string
  goal?: CETAGoal
  isSectionGoal: boolean
  onClose: () => void
  onConfirm: (goal: CETAGoal) => void
}

export const EditCETAGoalModal = (props: Props): JSX.Element => {
  const [isAddingAction, setIsAddingAction] = useState(false)
  const [isDeletingAction, setIsDeletingAction] = useState(false)
  const [isShowingExtendWarning, setIsShowingExtendWarning] = useState(false)
  const [selectedActionIndex, setSelectedActionIndex] = useState<number | undefined>(undefined)
  const [ownerOptions, setOwnerOptions] = useState<GoalOwner[]>([])
  const [targetMeasure, setTargetMeasure] = useState<number | undefined>(props.goal?.targetMeasure)
  const [owner, setOwner] = useState<GoalOwner | undefined>(props.goal?.owner)
  const [targetDate, setTargetDate] = useState<Date | null>(props.goal?.targetGoalDate ?? null)
  const [summary, setSummary] = useState<string>(props.goal?.summary ?? '')
  const [showGoalRequired, setShowGoalRequired] = useState(false)
  const [actions, setActions] = useState<CETAPlanAction[]>(props.goal?.actions ?? [])
  const [isLoading, setIsLoading] = useState(true)
  const extendTimeoutId = useRef<NodeJS.Timeout | null>(null)
  const closeTimeoutId = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    const fetchOwners = async (): Promise<void> => {
      const teamMembers = await GetCETATeam(props.classroomId)

      setOwnerOptions(teamMembers.map(member => ({
        id: member.userId,
        name: `${member.firstName} ${member.lastName}`,
        imageKey: member.imageKey
      })))

      setIsLoading(false)
    }

    void fetchOwners()
    startTimers()

    return () => {
      clearTimeout(extendTimeoutId.current as NodeJS.Timeout)
      clearTimeout(closeTimeoutId.current as NodeJS.Timeout)
    }
  }, [])

  const startTimers = (): void => {
    const timeoutId1 = setTimeout(() => {
      setIsShowingExtendWarning(true)
    }, 600000) // 10 minutes
    const timeoutId2 = setTimeout(() => {
      props.onClose()
    }, 900000) // 15 minutes
    extendTimeoutId.current = timeoutId1
    closeTimeoutId.current = timeoutId2
  }

  const handleOwnerChange = (e: SelectChangeEvent): void => {
    const selectedOwner = ownerOptions.find(o => o.id === parseInt(e.target.value))
    if (selectedOwner != null) {
      setOwner(selectedOwner)
    }
  }

  const deleteAction = (index: number): void => {
    setSelectedActionIndex(index)
    setIsDeletingAction(true)
  }

  const handleConfirmDeleteAction = (): void => {
    if (selectedActionIndex == null) {
      return
    }

    const newActions = [...actions]
    newActions.splice(selectedActionIndex, 1)
    setActions(newActions)
    setIsDeletingAction(false)
    setSelectedActionIndex(undefined)
    toast.success('Action deleted')
  }

  const handleConfirmGoal = (): void => {
    if (owner == null || targetDate == null || summary === '' || (!props.isSectionGoal && targetMeasure == null)) {
      setShowGoalRequired(true)
      toast.error('Please complete all required fields.')
      return
    }

    const newGoal: CETAGoal = {
      id: props.goal?.id,
      notes: [],
      targetMeasure,
      owner,
      targetGoalDate: targetDate,
      summary,
      actions
    }

    props.onConfirm(newGoal)
  }

  const handleConfirmAddAction = (newAction: CETAPlanAction): void => {
    setActions([...actions, newAction])
    setIsAddingAction(false)
    toast.success('Action added')
  }

  const handleConfirmExtendSession = async (): Promise<void> => {
    if (props.isSectionGoal) {
      await LockSectionGoal(props.sectionId ?? 0)
    } else {
      await LockIndicatorGoal(props.indicatorId ?? 0)
    }

    clearTimeout(closeTimeoutId.current as NodeJS.Timeout)
    setIsShowingExtendWarning(false)
    toast.success('Session extended')

    startTimers()
  }

  return <Modal
    open={true}
    title={`Goal - ${props.name}`}
    confirmButtonText={props.goal?.id == null ? 'Create Goal' : 'Confirm Edits'}
    onClose={props.onClose}
    onConfirm={handleConfirmGoal}
    data-testid='editGoalModal'
    bodyContent={
      isLoading
        ? <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}><CircularProgress /></Box>
        : <Box>
          {!props.isSectionGoal &&
            <>
              <RatingRadio
                initialValue={targetMeasure}
                name='targetMeasure'
                label='Target'
                required={showGoalRequired && targetMeasure == null}
                showRequiredText={showGoalRequired && targetMeasure == null}
                error={showGoalRequired && targetMeasure == null}
                onValueSelected={setTargetMeasure}
                sx={{ mb: '8px' }}
              />
            </>
          }

          <SelectWithLabel
            label='Owner'
            name='owner'
            required={showGoalRequired && owner == null}
            showRequiredText={showGoalRequired && owner == null}
            error={showGoalRequired && owner == null}
            value={owner?.id.toString() ?? ''}
            options={ownerOptions.map(o => ({ id: o.id.toString(), name: o.name }))}
            width='100%'
            onChange={handleOwnerChange}
          />

          <DatePickerWithLabel
            name='targetDate'
            label='Target Date'
            value={targetDate}
            required={showGoalRequired && targetDate == null}
            showRequiredText={showGoalRequired && targetDate == null}
            error={showGoalRequired && targetDate == null}
            dataTestId='goalTargetDate'
            onChange={setTargetDate}
            sx={{ mt: '8px' }}
          />

          <TextArea
            initialValue={summary}
            name='summary'
            label='Summary'
            placeholder='What is your goal?'
            required={showGoalRequired && summary === ''}
            showRequiredText={showGoalRequired && summary === ''}
            error={showGoalRequired && summary === ''}
            onChange={(e) => { setSummary(e.target.value) }}
            sx={{ mt: '8px' }}
          />

          <Box display='flex' my='8px' alignItems='center'>
            <Typography color='black'>Actions</Typography>

            <AddCircle
              onClick={() => { setIsAddingAction(true) }}
              data-testid='addAction'
              sx={{ width: '16px', height: '16px', ml: '.5em', color: '#004A70', cursor: 'pointer' }}
            />
          </Box>

          {actions.map((action, index) => (
            <CETAGoalActionDetails
              key={index}
              action={action}
              index={index}
              deleteAction={deleteAction}
            />
          ))}

          {actions.length === 0 && <Typography>No actions have been added</Typography>}

          {isAddingAction &&
            <AddCETAGoalActionModal
              ownerOptions={ownerOptions}
              onClose={() => { setIsAddingAction(false) }}
              onConfirm={handleConfirmAddAction}
            />
          }

          {isDeletingAction &&
            <BannerModal
              title='Delete Action'
              confirmButtonText='Delete Action'
              onClose={() => { setIsDeletingAction(false) }}
              onConfirm={handleConfirmDeleteAction}
              dataTestId='deleteActionModal'
              bodyContent={<Typography>Are you sure you want to delete this action?</Typography>}
            />
          }

          {isShowingExtendWarning &&
            <BannerModal
              title='Session Timeout'
              cancelButtonText='No'
              confirmButtonText='Yes'
              onClose={() => { setIsShowingExtendWarning(false) }}
              onConfirm={handleConfirmExtendSession}
              bodyContent={<Typography>You have 5 minutes left in your current edit session. Would you like to extend this? Failure to do so will result in loss of data.</Typography>}
            />
          }
        </Box>}
  />
}
