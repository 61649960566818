import { Cancel } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { ProfileImage } from '../../../customComponents/user/ProfileImage'
import { type CETAPlanAction } from '../../../../models/CETAPlanSectionModel'

interface Props {
  action: CETAPlanAction
  index?: number
  deleteAction?: (index: number) => void
}

export const CETAGoalActionDetails = (props: Props): JSX.Element => {
  const handleDelete = (): void => {
    if (props.deleteAction != null && props.index != null) {
      props.deleteAction(props.index)
    }
  }

  return <Box display='flex' alignItems='center' justifyContent='space-between' mb='1em' data-testid='actionDetails'>
    <Box display='flex'>
      <ProfileImage name={props.action.owner.name} imageKey={props.action.owner.imageKey} />

      <Box ml='.5em'>
        <Box display='flex'>
          <Typography fontSize='14px'>{props.action.owner.name}</Typography>
          <Typography fontSize='14px' ml='1em'>{new Date(props.action.targetDate).toLocaleDateString()}</Typography>
        </Box>

        <Typography fontSize='10px'>{props.action.summary}</Typography>
      </Box>
    </Box>

    {props.deleteAction != null &&
      <Cancel
        sx={{ width: '16px', height: '16px', ml: '1em', color: '#BF0649', cursor: 'pointer' }}
        onClick={handleDelete}
        data-testid='deleteAction'
      />
    }
  </Box>
}
