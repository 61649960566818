import { type MouseEvent as ReactMouseEvent, useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import MenuIcon from '@mui/icons-material/Menu'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import startLogo from '../../../assets/start.png'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../hooks/use-auth'
import { ProfileImage } from '../../customComponents/user/ProfileImage'
import { AuthFunctions, hasPermission } from '../../../utils/permissions'
import { generatePath } from 'react-router'
import { AppRoutes, getRouteFromString } from '../../Routes'
import { OrganizationSwitcher } from './OrganizationSwitcher'

interface DropdownOption {
  name: string
  onClick?: () => void
  onClickAsync?: () => Promise<void>
}

export default function NavBar (): JSX.Element {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)
  const nav = useNavigate()
  const auth = useAuth()

  const pages = ['Home']
  if (auth.user != null && !auth.user.noAccount && auth.user.isActive) {
    // pages.push('Planning')
    if (hasPermission(auth.user, AuthFunctions.CETA_PARTICIPATION)) {
      pages.push('Implementation')
    }
    // pages.push('Resources')
    // if (hasPermission(auth.user, AuthFunctions.REPORTS)) {
    //   pages.push('Reports')
    // }
  }

  const handleOpenNavMenu = (event: ReactMouseEvent<HTMLElement>): void => {
    setAnchorElNav(event.currentTarget)
  }

  const handleOpenUserMenu = (event: ReactMouseEvent<HTMLElement>): void => {
    setAnchorElUser(event.currentTarget)
  }

  const handleNavItemClicked = (event: any): void => {
    const replaced: string = event.currentTarget.textContent.toString().toUpperCase().replace(/ /g, '_')
    nav(getRouteFromString(replaced))
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = (): void => {
    setAnchorElUser(null)
  }

  const handleOptionClick = async (option: DropdownOption): Promise<void> => {
    if (option.onClick != null) {
      option.onClick()
    } else if (option.onClickAsync != null) {
      await option.onClickAsync()
    }
    setAnchorElUser(null)
  }

  const settingsOptions: DropdownOption[] = []
  if (auth.user != null &&
      (hasPermission(auth.user, AuthFunctions.PROJECT_SETTINGS) ||
      hasPermission(auth.user, AuthFunctions.RCN_MANAGEMENT) ||
      hasPermission(auth.user, AuthFunctions.ISD_MANAGEMENT) ||
      hasPermission(auth.user, AuthFunctions.DISTRICT_MANAGEMENT) ||
      hasPermission(auth.user, AuthFunctions.RCN_ADMINISTRATION) ||
      hasPermission(auth.user, AuthFunctions.ISD_ADMINISTRATION) ||
      hasPermission(auth.user, AuthFunctions.DISTRICT_ADMINISTRATION))) {
    const adminRoute = generatePath(AppRoutes.ADMINISTRATION, { id: auth.user.currentOrgId.toString() })
    settingsOptions.push({ name: 'Admin Settings', onClick: () => { nav(adminRoute) } })
  }
  if (auth.isImpersonating()) {
    settingsOptions.push({ name: 'Stop Impersonating', onClickAsync: auth.endImpersonation })
  }
  settingsOptions.push({ name: 'Logout', onClick: auth.logout })

  return (
    <AppBar position="static" sx={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'white' }}>
      <Box sx={{ px: '1em' }}>
        <Toolbar disableGutters>
          {auth.user != null && !auth.user.noAccount && auth.user.isActive &&
            <OrganizationSwitcher isMobileView={false} />
          }

          <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
            <img src={startLogo} height={40} alt='logo' data-testid="startLogo" style={{ textAlign: 'center' }} />
          </Box>

          <Typography
            variant="h6"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none'
            }}
          >
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon sx={{ fill: 'unset' }} />
            </IconButton>

            {auth.user != null && !auth.user.noAccount && auth.user.isActive &&
              <OrganizationSwitcher isMobileView={true} />
            }

            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              open={anchorElNav != null}
              onClose={handleNavItemClicked}
              sx={{ display: { xs: 'block', md: 'none' } }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleNavItemClicked}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
            <img src={startLogo} height={40} alt='logo' data-testid="startLogo" style={{
              textAlign: 'center'
            }}
            />
          </Box>

          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none'
            }}
          >
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleNavItemClicked}
                sx={{ p: '1em', color: 'black', display: 'block', margin: 0, textTransform: 'none', fontSize: '1em' }}
              >
                {page}
              </Button>
            ))}
          </Box>

          {auth.user != null && <Box sx={{ flexGrow: 0 }}>
            <Button sx={{ display: 'flex', alignItems: 'center', color: 'initial', textTransform: 'none' }} onClick={handleOpenUserMenu}>
              <ProfileImage name={auth.user.firstName} imageKey={auth.user.imageKey} />
              <Typography sx={{ ml: '5px' }}>
                {'Hi, ' + auth.user.firstName}
              </Typography>
              <ArrowDropDownIcon />
            </Button>

            <Menu
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={anchorElUser != null}
              onClose={handleCloseUserMenu}
            >
              {settingsOptions.map((setting) => (
                <MenuItem key={setting.name} onClick={async () => { await handleOptionClick(setting) }}>
                  <Typography textAlign="center">{setting.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>}
        </Toolbar>
      </Box>
    </AppBar>
  )
}
