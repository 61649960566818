import { Box } from '@mui/material'
import { type UserPermission } from '../../../../utils/genericTypes'
import { SquareSwitch } from '../../../customComponents/SquareSwitch'

interface Props {
  functionOptions: UserPermission[]
  headers: JSX.Element[]
  forceReadOnly?: boolean
  selectedFunctions: string[]
  setSelectedFunctions: (newValue: string[]) => void
}

export function FunctionCheckList (props: Props): JSX.Element {
  const overall: JSX.Element[] = []

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    const newFunctions = getNewlySelectedFunctions(e.target.name, checked)
    props.setSelectedFunctions(newFunctions)
  }

  const getNewlySelectedFunctions = (name: string, checked: boolean): string[] => {
    let newFunctions = [...props.selectedFunctions]
    const namePieces = name.split('_')
    const isEdit = namePieces[namePieces.length - 1] === 'Edit'
    if (isEdit) {
      namePieces.pop()
    }

    if (checked) {
      // Add the selected function and all parent functions
      while (namePieces.length > 0) {
        const viewName = namePieces.join('_')
        if (!newFunctions.includes(viewName)) {
          newFunctions.push(viewName)
        }
        if (isEdit) {
          const editName = viewName + '_Edit'
          if (!newFunctions.includes(editName)) {
            newFunctions.push(editName)
          }
        }
        namePieces.pop()
      }
    } else {
      // Remove the selected function and all child functions
      newFunctions = newFunctions.filter(f => !f.startsWith(name))
      if (isEdit) {
        newFunctions = newFunctions.filter(f => !f.startsWith(namePieces.join('_')) || !f.endsWith('Edit'))
      }
    }
    return newFunctions
  }

  generateRows(props.functionOptions, 0)

  function generateRows (funcs: UserPermission[], indents: number): void {
    if (funcs.length > 0) {
      funcs.forEach(f => {
        const readExists = f.readPermissionName !== null
        const writeExists = f.writePermissionName !== null
        const key = readExists ? f.readPermissionName : f.writePermissionName
        const forceRead = props.forceReadOnly === true

        const readButton = <td>
          {readExists && (
            <SquareSwitch
              name={f.readPermissionName}
              disabled={f.readImplicit || forceRead}
              checked={props.selectedFunctions.find(sf => sf === f.readPermissionName) !== undefined}
              onChange={handleChange}
              data-testid={`${f.readPermissionName ?? ''}-Checkbox`}
              sx={{ ml: '3em' }}
            />
          )}
        </td>

        const writeButton = <td>
          {writeExists && (
            <SquareSwitch
              name={f.writePermissionName}
              disabled={f.writeImplicit || forceRead}
              checked={props.selectedFunctions.find(sf => sf === f.writePermissionName) !== undefined}
              onChange={handleChange}
              data-testid={`${f.writePermissionName ?? ''}-Checkbox`}
              sx={{ ml: '3em' }}
            />
          )}
        </td>

        overall.push(
          <tr key={key} data-testid={f.nodeName + 'Checkboxes'}>
            <Box component='td' sx={{ pl: 4 * indents }}>
              {f.nodeName}
            </Box>
            {readButton}
            {writeButton}
          </tr>
        )

        generateRows(f.subPermissions, indents + 1)
      })
    }
  }

  return <table width='100%'>
    <thead>
      <tr>
        {props.headers.map(h => {
          return <td key={h.props.labelText}>{h}</td>
        })}
      </tr>
    </thead>

    <tbody>
      {overall.map(f => { return f })}
    </tbody>
  </table>
}
