import type { CETAGoalNote, CETAPlanSectionModel } from '../../../../models/CETAPlanSectionModel'
import { CETAGoalDetails } from './CETAGoalDetails'
import { Box, Breadcrumbs, Card, Link, Typography } from '@mui/material'
import { RatingRadio } from '../../../customComponents/RatingRadio'
import { ProfileImage } from '../../../customComponents/user/ProfileImage'
import { useEffect, useState } from 'react'
import generatePDF, { Margin } from 'react-to-pdf'
import { GetCETAPlanSections } from '../../../../services/CETAClassroomService'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { BlueButton } from '../../../customComponents/buttons/BlueButton'
import DownloadIcon from '@mui/icons-material/Download'
import { AppRoutes } from '../../../Routes'
import { CETAOverviewTabs } from '../CETAOverview'
import { TranslateSectionDates } from '../../../../utils/cetaUtils'

export const CETAPlanSectionPrint = (): JSX.Element => {
  const [sections, setSections] = useState<CETAPlanSectionModel[]>([])
  const { classroomId, sectionIds } = useParams()
  const nav = useNavigate()

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const sectionVariable = await GetCETAPlanSections(sectionIds?.split(',') ?? [])
      sectionVariable.forEach(s => {
        s = TranslateSectionDates(s)
      })
      setSections(sectionVariable)
    }

    void fetchData()
  }, [])

  const getDateLine = (event: Date): string => {
    return event.toLocaleDateString([], { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
  }

  const downloadSections = async (): Promise<void> => {
    const getTargetElement = (): HTMLElement | null => document.getElementById('pdf-container')
    await generatePDF(getTargetElement, { method: 'open', page: { margin: Margin.MEDIUM, format: 'letter', orientation: 'portrait' } })
  }

  const goBack = (): void => {
    if (classroomId !== undefined) {
      const backRoute = generatePath(AppRoutes.CETA_OVERVIEW, { classroomId, tab: CETAOverviewTabs.Plan })
      nav(backRoute)
    } else {
      nav(-1)
    }
  }

  return <>
    <Box>
      <Breadcrumbs maxItems={2} aria-label="breadcrumb" sx={{ margin: '1.25em' }}>
        <Link sx={{ cursor: 'pointer' }} underline="hover" color="inherit" onClick={goBack} data-testid='backToPlan'>
          <ArrowBackIcon sx={{ verticalAlign: 'middle', mr: '5px' }} />
          Back to Plan Sections
        </Link>
      </Breadcrumbs>
    </Box>
    <Typography variant='h3' className='page-header'>Section Action Plans</Typography>
    <Box sx={{ width: '75%', display: 'flex', marginLeft: '1em', marginBottom: '1em', alignContent: 'center' }}>
      <Typography sx={{ width: '75%', fontWeight: '500' }} variant='h5'>Selected Sections</Typography>
      <BlueButton onClick={downloadSections} sx={{ marginLeft: '1em', justifyContent: 'space-between' }} dataTestId='downloadGoals'>
        Download Action Plans
        <DownloadIcon />
      </BlueButton>
    </Box>
    <div id='pdf-container'>
      {sections?.map((sec, ind) => {
        return <Card data-testid={`${sec.name}`} key={`${sec.name}${ind}paper`}>
          <Box sx={{ margin: '1em' }}>
            <Typography variant='h4' sx={{ my: '.5em' }}>{sec.name}</Typography>
            <CETAGoalDetails key={sec.name} goal={sec.goal}></CETAGoalDetails>
            {sec.goal?.notes?.map((note: CETAGoalNote) => {
              return <Box display='flex' key={`${note.id}-secnote`}>
                <ProfileImage name={note.owner.name} imageKey={note.owner.imageKey} />
                <Box ml='.5em'>
                  <Box display='flex'>
                    <Typography fontSize='14px'>{note.owner.name}</Typography>
                    <Typography fontSize='14px' ml='1em'>{new Date(note.updateDate).toLocaleDateString()}</Typography>
                  </Box>

                  <Typography fontSize='10px'>{note.note}</Typography>
                </Box>
                <hr></hr>
              </Box>
            })}
            { sec.indicators.map((ind) => {
              return ind.goal != null && <Box key={`indHeading${ind.name}`}>
                <Typography variant='h5' sx={{ my: '.5em' }}>{ind.name}</Typography>
                <Box display='flex' alignItems='center' mb='1em' data-testid='indicatorGoalHeader'>
                  <Typography fontSize='1.25em' mr='1em'>Indicator Goal</Typography>

                  <RatingRadio
                    readOnly={true}
                    initialValue={ind.goal.targetMeasure}
                    checkedBackgroundColor='#50AE1F'
                    checkedBorderColor='#2D6510'
                  />
                </Box>
                <CETAGoalDetails goal={ind.goal} />
                {ind.goal?.notes?.map((note) => {
                  return <Box key={`${note.id}-indnote`}>
                    <Typography mt='1em' mb='.5em' fontWeight='600'>Progress Updates</Typography>
                    <Box display='flex'>
                      <ProfileImage name={note.owner.name} imageKey={note.owner.imageKey} />
                      <Box ml='.5em'>
                        <Typography fontSize='11px' ml='1em'>{`${getDateLine(new Date(note.updateDate))} by ${note.owner.name}`}</Typography>
                        <Typography fontSize='12px' ml='1em'>{note.note}</Typography>
                      </Box>
                    </Box>
                  </Box>
                })}
              </Box>
            })}
          </Box>
        </Card>
      })}
    </div>
  </>
}
