import React, { useContext } from 'react'
import { useNavigate } from 'react-router'
import { sendPost } from './use-fetch'
import { AuthContext } from '../contextProviders/AuthProvider'
import { type SecurityLevel } from '../utils/genericTypes'

export interface AuthUser {
  id: number
  email: string
  firstName: string
  lastName: string
  imageKey?: string
  isImpersonating: boolean
  noAccount: boolean
  isActive: boolean
  currentOrgId: number
  securityLevel: SecurityLevel
  userFunctions: string[]
}

export interface AuthType {
  loading: boolean
  user: AuthUser | null
  miCredsLogin: () => void
  miLaunchPadLogin: () => void
  logout: () => void
  setCurrentUser: () => Promise<void>
  impersonate: (userId: number) => Promise<void>
  endImpersonation: () => Promise<void>
  isImpersonating: () => boolean
  setCurrentOrg: (orgId: number) => Promise<void>
}

export function useAuth (): AuthType {
  const serverUrl = process.env.REACT_APP_BACKEND_URL ?? ''
  const clientUrl = process.env.REACT_APP_FRONTEND_URL ?? ''
  const auth = useContext(AuthContext)
  const nav = useNavigate()

  const miCredsLogin = (): void => {
    window.location.href = `${serverUrl}/Account/MiCredsLogin?returnUrl=${clientUrl}`
  }

  const miLaunchPadLogin = (): void => {
    window.location.href = `${serverUrl}/Account/MiLaunchPadLogin?returnUrl=${clientUrl}`
  }

  const logout = (): void => {
    window.location.href = `${serverUrl}/Account/Logout`
  }

  const impersonate = async (userId: number): Promise<void> => {
    await sendPost('Account/ImpersonateUser', { userId })
    nav('/')
    window.location.reload()
  }

  const endImpersonation = React.useCallback(async () => {
    await sendPost('Account/EndImpersonation', {})
    nav('/')
    window.location.reload()
  }, [])

  const isImpersonating = (): boolean => {
    return auth?.user?.isImpersonating ?? false
  }

  const setCurrentOrg = async (orgId: number): Promise<void> => {
    await sendPost('Account/SetCurrentOrg', { orgId })
    nav('/')
    window.location.reload()
  }

  return {
    loading: auth.loading,
    user: auth.user,
    miCredsLogin,
    miLaunchPadLogin,
    logout,
    setCurrentUser: auth.setCurrentUser,
    impersonate,
    endImpersonation,
    isImpersonating,
    setCurrentOrg
  }
}
