import { sendGet, sendPost } from '../hooks/use-fetch'
import { type CETATemplateInstructions } from '../models/CETATemplateInstructions'

export async function getCETATemplateInstructions (): Promise<CETATemplateInstructions> {
  const { response } = await sendGet('CETATemplate/GetInstructions')
  return response
}

export async function saveCETATemplateInstructions (template: CETATemplateInstructions): Promise<void> {
  await sendPost('CETATemplate/SaveInstructions', template)
}
