import { Box, Chip } from '@mui/material'
import { OrganizationStatus, OrganizationType, type SelectableOption } from '../../../utils/genericTypes'
import { BlockButton } from '../../customComponents/buttons/BlockButton'
import DataTable from '../../customComponents/dataTable/DataTable'
import { Column, ColumnType, ColumnSort } from '../../customComponents/dataTable/DataTable.model'
import { ProfileImage } from '../../customComponents/user/ProfileImage'
import { type PaginationProps, usePagination } from '../../../hooks/use-pagination'
import { Colors } from '../../../utils/colors'
import { type OrganizationListModel } from '../../../models/OrganizationListModel'
import { useContext, useEffect, useState } from 'react'
import { generatePath, useNavigate } from 'react-router'
import { type OrgSearchValues, OrganizationSearch } from './OrganizationSearch'
import { AppRoutes } from '../../Routes'
import { OrgTabs } from './OrganizationDetails'
import { GetOrgList } from '../../../services/OrganizationService'
import { AddRCNModal } from './AddRCNModal'
import { AddDistrictModal } from './AddDistrictModal'
import { AddBuildingModal } from './AddBuildingModal'
import { EditBuildingModal } from './EditBuildingModal'
import { BreadcrumbContext, SetBreadcrumbContext } from '../../../contextProviders/BreadcrumbProvider'
import { type OrganizationDetailsModel } from '../../../models/OrganizationDetailsModel'

interface Props {
  primaryOrg: OrganizationDetailsModel
}

export function OrganizationList (props: Props): JSX.Element {
  const [isLoading, setIsLoading] = useState(true)
  const [isAddingRCN, setIsAddingRCN] = useState(false)
  const [isAddingDistrict, setIsAddingDistrict] = useState(false)
  const [isAddingBuilding, setIsAddingBuilding] = useState(false)
  const [isEditingBuilding, setIsEditingBuilding] = useState(false)
  const [orgs, setOrgs] = useState<OrganizationListModel[]>([])
  const [searchValues, setSearchValues] = useState<OrgSearchValues>({ status: null, name: '' })
  const [selectedBuildingId, setSelectedBuildingId] = useState<number | null>(null)

  const nav = useNavigate()
  const breadcrumbs = useContext(BreadcrumbContext)
  const setBreadcrumbs = useContext(SetBreadcrumbContext)

  const paginationProps: PaginationProps<OrganizationListModel> = {
    rows: orgs
  }
  const pagination = usePagination(paginationProps)

  const loadData = async (): Promise<void> => {
    setIsLoading(true)

    const orgsResponse = await GetOrgList({
      status: searchValues.status,
      name: searchValues.name,
      parentOrgId: props.primaryOrg.id,
      parentOrgType: props.primaryOrg.type
    })
    setOrgs(orgsResponse)

    setIsLoading(false)
  }

  useEffect(() => {
    void loadData()
  }, [searchValues, props.primaryOrg])

  const handleEditOrg = (org: OrganizationListModel): void => {
    if (props.primaryOrg.type === OrganizationType.District) {
      setIsEditingBuilding(true)
      setSelectedBuildingId(org.id)
    } else {
      const currentRoute = generatePath(AppRoutes.ORGANIZATION_DETAILS, { id: props.primaryOrg.id.toString(), tab: OrgTabs.OrganizationList })
      setBreadcrumbs([...breadcrumbs, { name: props.primaryOrg.name, route: currentRoute }])

      const detailsRoute = generatePath(AppRoutes.ORGANIZATION_DETAILS, { id: org.id.toString(), tab: OrgTabs.Overview })
      nav(detailsRoute)
    }
  }

  const handleAddOrgClick = (): void => {
    if (props.primaryOrg.type === OrganizationType.Project) {
      setIsAddingRCN(true)
    } else if (props.primaryOrg.type === OrganizationType.ISD) {
      setIsAddingDistrict(true)
    } else if (props.primaryOrg.type === OrganizationType.District) {
      setIsAddingBuilding(true)
    }
  }

  const nameColumn = new Column('name', 'Organization Name', ColumnType.CUSTOM)
  nameColumn.customCellGenerator = (row: OrganizationListModel) => {
    return <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <ProfileImage name={row.name} imageKey={row.imageKey} />
      <Box sx={{ ml: '.3125em' }}>{row.name}</Box>
    </Box>
  }

  const statusColumn = new Column('status', 'Status', ColumnType.CUSTOM)
  statusColumn.customCellGenerator = (row: OrganizationListModel) => {
    let color = ''
    let backgroundColor = ''
    let border = '1px solid '
    if (row.status === OrganizationStatus.Active) {
      color = Colors.ACTIVE_TEXT
      backgroundColor = Colors.ACTIVE_BACKGROUND
      border += Colors.ACTIVE_BORDER
    } else if (row.status === OrganizationStatus.Inactive) {
      color = Colors.INACTIVE_TEXT
      backgroundColor = Colors.INACTIVE_BACKGROUND
      border += Colors.INACTIVE_BORDER
    } else if (row.status === OrganizationStatus.Onboarding) {
      color = Colors.PENDING_TEXT
      backgroundColor = Colors.PENDING_BACKGROUND
      border += Colors.PENDING_BORDER
    }

    return <>
      <Chip label={row.status} sx={{ color, backgroundColor, border, fontWeight: 'bold' }} />
    </>
  }

  let childOrgLabel = 'Organizations'
  if (props.primaryOrg.type === OrganizationType.Project) {
    childOrgLabel = 'ISDs'
  } else if (props.primaryOrg.type === OrganizationType.RCN) {
    childOrgLabel = 'Districts'
  } else if (props.primaryOrg.type === OrganizationType.ISD) {
    childOrgLabel = 'Buildings'
  }

  const childOrgsColumn = new Column('childOrgs', childOrgLabel, ColumnType.CUSTOM)
  childOrgsColumn.customCellGenerator = (row: OrganizationListModel) => {
    if (row.childOrgs.length <= 3) {
      return <>
        {row.childOrgs.map((org: SelectableOption) => {
          return <Box key={org.id}>{org.name}</Box>
        })}
      </>
    }

    return <>
      {row.childOrgs.slice(0, 2).map((org: SelectableOption) => {
        return <Box key={org.id}>{org.name}</Box>
      })}
      <Box>{row.childOrgs.length - 2} others</Box>
    </>
  }

  const lastChangedColumn = new Column('updatedAt', 'Last Change', ColumnType.CUSTOM)
  lastChangedColumn.customCellGenerator = (row: OrganizationListModel) => {
    return <>
      <Box>
        {row.updatedBy}
      </Box>
      <Box>
        {new Date(row.updatedAt.toString() + 'Z').toLocaleString([], { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' })}
      </Box>
    </>
  }

  const detailsColumn = new Column('', '', ColumnType.CUSTOM)
  detailsColumn.sortable = false
  detailsColumn.customCellGenerator = (row: OrganizationListModel) => {
    return <BlockButton onClick={() => { handleEditOrg(row) }} dataTestId={row.id.toString() + '-details'}>
      Details
    </BlockButton>
  }

  let columns = [nameColumn, statusColumn, childOrgsColumn, lastChangedColumn, detailsColumn]
  if (props.primaryOrg.type === OrganizationType.District) {
    columns = [nameColumn, statusColumn, lastChangedColumn, detailsColumn]
  }

  let orgLabel = 'Organization'
  if (props.primaryOrg.type === OrganizationType.Project) {
    orgLabel = 'RCN'
  } else if (props.primaryOrg.type === OrganizationType.RCN) {
    orgLabel = 'ISD'
  } else if (props.primaryOrg.type === OrganizationType.ISD) {
    orgLabel = 'District'
  } else if (props.primaryOrg.type === OrganizationType.District) {
    orgLabel = 'Building'
  }

  return <Box sx={{ width: 'fit-content' }}>
    <OrganizationSearch
      parentOrgId={props.primaryOrg.id}
      parentOrgType={props.primaryOrg.type}
      orgLabel={orgLabel}
      shouldShowAddButton={props.primaryOrg.type !== OrganizationType.RCN}
      handleAddNewOrg={handleAddOrgClick}
      filtersUpdated={setSearchValues}
    />

    <DataTable
      name='organizations'
      rows={pagination.internalRows}
      columns={columns}
      page={pagination.page}
      totalRecords={pagination.recordCount}
      loading={isLoading}
      initialColumnSorts={[new ColumnSort('name')]}
      onSortChange={(col, sorts) => { pagination.onSortChange(col, sorts[0].order) }}
      onPageChange={pagination.handleChangePage}
      onRowsPerPageChange={pagination.handleChangeRowsPerPage}
    />

    {isAddingRCN &&
      <AddRCNModal
        parentOrgId={props.primaryOrg.id}
        onClose={() => { setIsAddingRCN(false) }}
        onSubmit={loadData}
      />
    }

    {isAddingDistrict &&
      <AddDistrictModal
        parentOrgId={props.primaryOrg.id}
        onClose={() => { setIsAddingDistrict(false) }}
        onSubmit={loadData}
      />
    }

    {isAddingBuilding &&
      <AddBuildingModal
        districtId={props.primaryOrg.id}
        onClose={() => { setIsAddingBuilding(false) }}
        onSubmit={loadData}
      />
    }

    {isEditingBuilding && selectedBuildingId != null &&
      <EditBuildingModal
        buildingId={selectedBuildingId}
        onClose={() => { setIsEditingBuilding(false) }}
        onSubmit={loadData}
      />
    }
  </Box>
}
