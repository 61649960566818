import { Box, Card, TextField, Typography } from '@mui/material'
import { type NeedsAttentionModel } from '../../../../models/NeedsAttentionModel'
import { OrganizationAccordion } from './OrganizationAccordion'
import { AccessRequestAccordion } from './AccessRequestAccordion'
import { WarningCard } from '../../../customComponents/WarningCard'
import { useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import { type OrganizationDetailsModel } from '../../../../models/OrganizationDetailsModel'

interface Props {
  primaryOrg: OrganizationDetailsModel
  needAttentionEntities: NeedsAttentionModel[]
}

export const NeedsAttentionCard = (props: Props): JSX.Element => {
  const [searchValue, setSearchValue] = useState<string>('')
  const [filteredEntities, setFilteredEntities] = useState<NeedsAttentionModel[]>(props.needAttentionEntities)

  useEffect(() => {
    setFilteredEntities(props.needAttentionEntities)
  }, [props.needAttentionEntities])

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value
    setSearchValue(value)

    const filtered = props.needAttentionEntities.filter(entity =>
      (entity.organization?.name?.toLowerCase().includes(value.toLowerCase()) ?? false) ||
      `${entity.accessRequest?.firstName ?? ''} ${entity.accessRequest?.lastName ?? ''}`.toLowerCase().includes(value.toLowerCase()))

    setFilteredEntities(filtered)
  }

  return <Card sx={{ p: '1.25em', backgroundColor: '#F3F3F380', border: '1px solid #CBC8CC' }}>
    <Typography variant='h4' sx={{ fontWeight: '600', textAlign: 'center', fontSize: '1.5em' }}>Needs Attention</Typography>

    <Box sx={{ position: 'relative' }}>
      <TextField
        label='Name'
        name='nameSearch'
        onChange={onNameChange}
        value={searchValue}
        sx={{ width: '100%' }}
        data-testid='nameSearch'
      />
      <SearchIcon sx={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '0.5em' }} />
    </Box>

    {filteredEntities.map((entity, index) => (
      <Box key={index} sx={{ my: '.625em' }} data-testid='needsAttentionItem'>
        {entity.organization != null && <OrganizationAccordion primaryOrg={props.primaryOrg} organization={entity.organization} />}
        {entity.accessRequest != null && <AccessRequestAccordion primaryOrg={props.primaryOrg} accessRequest={entity.accessRequest} />}
      </Box>
    ))}

    {filteredEntities.length === 0 && <WarningCard message='No items need attention' />}
  </Card>
}
