import { Modal } from '@oaisd/michdev.components.react'
import { type SyntheticEvent, useEffect, useState } from 'react'
import type { AddCETAClassroomModel } from '../../../models/AddCETAClassroomModel'
import { type SelectableOption, YesNoAnswer } from '../../../utils/genericTypes'
import { Box, CircularProgress, FormControlLabel, Radio, RadioGroup, type SelectChangeEvent, Typography } from '@mui/material'
import { UserDetails } from '../../customComponents/user/Details'
import type { User } from '../../../models/User'
import { SelectWithLabel } from '../../customComponents/SelectWithLabel'
import { AddCETAClassroom, GetUserDetails, GetTeacherAndCoachOptionsForCurrentOrg } from '../../../services/CETAClassroomService'
import MUIAutoComplete from '../../customComponents/MUIAutoComplete'
import { useAuth } from '../../../hooks/use-auth'
import type { FieldError } from '../../../hooks/use-fetch'
import { toast } from 'react-toastify'

interface Props {
  timeSpanId: number
  closeModal: () => void
  handleAddClassroom: () => void
}

export interface AddCETAClassroomDDLOptions {
  coaches: SelectableOption[]
  teachers: SelectableOption[]
}

export const AddCETAClassroomModal = (props: Props): JSX.Element => {
  const { user } = useAuth()

  const [isLoading, setIsLoading] = useState(true)
  const [teacherOptions, setTeacherOptions] = useState<SelectableOption[]>([])
  const [selectedTeacher, setSelectedTeacher] = useState<User>()
  const [buildingOptions, setBuildingOptions] = useState<SelectableOption[]>([])
  const [selectedBuilding, setSelectedBuilding] = useState<number>()
  const [selectedPurposeAnswer, setSelectedPurpose] = useState('')
  const [selectedRCNAnswer, setSelectedRCNAnswer] = useState<SelectableOption>({ id: 2, name: YesNoAnswer.Unsure })
  const [selectedCoachAnswer, setSelectedCoachAnswer] = useState<SelectableOption>({ id: 2, name: YesNoAnswer.Unsure })
  const [coachOptions, setCoachOptions] = useState<SelectableOption[]>([])
  const [selectedCoach, setSelectedCoach] = useState<SelectableOption>()
  const [fieldErrors, setFieldErrors] = useState<FieldError[]>([])
  const [userIsACoach, setUserIsACoach] = useState(false)

  const rcnContract: string = '1. Is this CETA being completed as part of the RCN Contract Activities?'
  const purpose: string = '2. What is your purpose for completing the CETA? *'
  const coachExists: string = '3. Is there a START-trained coach on this team?'
  const coachSelectLabel: string = '4. Who is the coach for this classroom?'
  const purposeOption1: string = 'I am a teacher using the CETA as a self-reflection tool'
  const purposeOption2: string = 'I am a member of a team focused on supporting the classroom'
  const purposeOption3: string = 'I am a member of a team focused on classroom practices to support a specific student'
  const yesNoOptions: SelectableOption[] = [{ id: 0, name: YesNoAnswer.Yes }, { id: 1, name: YesNoAnswer.No }, { id: 2, name: YesNoAnswer.Unsure }]

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setIsLoading(true)
      const ddlOptions: AddCETAClassroomDDLOptions = await GetTeacherAndCoachOptionsForCurrentOrg()
      const teacherUser = ddlOptions.teachers.find((o) => o.id === (user?.id ?? 0))
      setTeacherOptions(ddlOptions.teachers)
      if (teacherUser !== undefined) {
        await changeTeacher(teacherUser.id.toString())
      }

      const userIsCoach = ddlOptions.coaches.find((o) => o.id === (user?.id ?? 0))
      setUserIsACoach(userIsCoach !== undefined)
      if (userIsCoach !== undefined) {
        setSelectedCoachAnswer({ id: 0, name: YesNoAnswer.Yes })
        setSelectedCoach(userIsCoach)
      }

      setCoachOptions(ddlOptions.coaches)
      setIsLoading(false)
    }

    void fetchData()
  }, [])

  const onTeacherChanged = async (e: SyntheticEvent, value: { id: string | number, name: string } | null): Promise<void> => {
    if (value == null) {
      setSelectedTeacher(undefined)
      setBuildingOptions([])
      setSelectedBuilding(undefined)
      return
    }

    await changeTeacher(value.id as string)
  }

  const changeTeacher = async (teacherValue: string): Promise<void> => {
    const selectedTeach = await GetUserDetails(teacherValue)
    setSelectedTeacher(selectedTeach)
    const buildings = selectedTeach.organizations.find((org) => org.id === user?.currentOrgId)?.buildings ?? []
    setBuildingOptions(buildings)
    if (buildings.length > 0) {
      setSelectedBuilding(buildings[0].id)
    }
  }

  const onCoachChanged = async (e: SyntheticEvent, value: { id: string | number, name: string } | null): Promise<void> => {
    if (value !== null) {
      setSelectedCoach({ id: value.id as number, name: value.name })
    } else {
      setSelectedCoach(undefined)
    }
  }

  const handleRCNSelect = (e: SelectChangeEvent<number>): void => {
    const rcnId = e.target.value
    setSelectedRCNAnswer(yesNoOptions.find((opt) => opt.id.toString() === rcnId) ?? yesNoOptions[2])
  }

  const handleCoachSelect = (e: SelectChangeEvent<number>): void => {
    const coachId = e.target.value
    const answer: SelectableOption | undefined = yesNoOptions.find((opt) => opt.id.toString() === coachId)
    setSelectedCoachAnswer(answer ?? yesNoOptions[2])
    if (answer?.name !== YesNoAnswer.Yes) {
      setSelectedCoach(undefined)
    }
  }

  const handleBuildingSelect = (e: SelectChangeEvent<number>): void => {
    const buildingId = e.target.value as number
    setSelectedBuilding(buildingId)
  }

  const handleSubmit = async (): Promise<void> => {
    const errors: FieldError[] = validateRequiredFields()

    if (errors.length === 0) {
      const submitClass: AddCETAClassroomModel = {
        cetaPurpose: selectedPurposeAnswer,
        hasStartCoach: selectedCoachAnswer.name,
        isRCNContract: selectedRCNAnswer.name,
        teacherId: selectedTeacher?.id,
        buildingId: selectedBuilding ?? 0,
        coachId: selectedCoach === undefined ? null : selectedCoach.id,
        timeSpanId: props.timeSpanId
      }
      const errorString: string = await AddCETAClassroom(submitClass)
      if (errorString !== '') {
        toast.error(errorString)
      } else {
        props.handleAddClassroom()
      }
    } else {
      setFieldErrors(errors)
      toast.error('Please fill out all required fields.')
    }
  }

  function validateRequiredFields (): FieldError[] {
    const errors: FieldError[] = []
    if (selectedTeacher == null) {
      errors.push({ fieldName: 'teacherSelect', message: 'Teacher is required.' })
    } else {
      if (buildingOptions.length === 0) {
        errors.push({ fieldName: 'buildings', message: 'Teacher must be associated with a building.' })
      }
    }
    if (selectedPurposeAnswer === '') {
      errors.push({ fieldName: 'purposeSelect', message: 'Purpose is required.' })
    }
    return errors
  }

  return <Modal
    title={'CETA Classroom'}
    open={true}
    maxWidth='lg'
    onClose={props.closeModal}
    onConfirm={handleSubmit}
    noButtons={false}
    buttonClassName='modal-button'
    confirmButtonClassName='modal-confirm-button'
    confirmButtonText='Save Changes'
    confirmationContent={
      isLoading
        ? <CircularProgress />
        : <Box sx={{ display: 'flex' }}>
          <Box sx={{ marginBottom: '.5em', width: '40%' }}>
            <MUIAutoComplete
              label='Teacher'
              name='teacherSelect'
              disabled={!userIsACoach}
              value={selectedTeacher != null ? { id: selectedTeacher.id, name: `${selectedTeacher.firstName} ${selectedTeacher.lastName}` } : undefined }
              onChange={onTeacherChanged}
              required={true}
              showRequiredText={fieldErrors.some((error) => error.fieldName === 'teacherSelect')}
              options={teacherOptions}
              sx={{ width: '100%', marginBottom: '1em', marginTop: '.5em' }}
            />
            <UserDetails
              maxWidth='100%'
              imageKey={selectedTeacher?.imageKey}
              detailName={selectedTeacher?.firstName ?? ''}
              isOrganizationView={true}
              infoDisplay={'Teacher Information'}
              users={selectedTeacher === undefined ? [] : [selectedTeacher]}
              isEditable={false}
              onUpdate={() => {}}
            />
          </Box>
          <Box sx={{ margin: '1em', width: '60%' }}>
            <SelectWithLabel
              name='rcnContractSelect'
              labelContent={<Typography sx={{ fontWeight: 'bold', marginBottom: '1em', marginTop: '1em' }} variant='body2'>{rcnContract}</Typography>}
              value={selectedRCNAnswer.id}
              options={yesNoOptions}
              width='40%'
              onChange={handleRCNSelect}
            />
            <Typography sx={{ fontWeight: 'bold', marginBottom: '1em', marginTop: '1em' }} variant='body2'>{purpose}</Typography>
            <RadioGroup onChange={(e, value) => { setSelectedPurpose(value) }} value={selectedPurposeAnswer}>
              {fieldErrors.some((error) => error.fieldName === 'purposeSelect') && <Box sx={{ fontWeight: 400, color: '#E00000' }}>Required</Box>}
              <FormControlLabel data-testid='purposeoption1' value={purposeOption1} control={<Radio />} label={purposeOption1} />
              <FormControlLabel data-testid='purposeoption2' value={purposeOption2} control={<Radio />} label={purposeOption2} />
              <FormControlLabel data-testid='purposeoption3' value={purposeOption3} control={<Radio />} label={purposeOption3} />
            </RadioGroup>
            <SelectWithLabel
              name='coachExistsSelect'
              labelContent={ <Typography sx={{ fontWeight: 'bold', marginBottom: '1em', marginTop: '1em' }} variant='body2'>{coachExists}</Typography> }
              value={selectedCoachAnswer.id}
              options={yesNoOptions}
              width='40%'
              onChange={handleCoachSelect}
            />
            { selectedCoachAnswer.name === YesNoAnswer.Yes &&
              <MUIAutoComplete
                separateLabel={true}
                labelContent={<Typography sx={{ fontWeight: 'bold', marginBottom: '1em', marginTop: '1em' }} variant='body2'>{coachSelectLabel}</Typography>}
                name='coachSelect'
                value={selectedCoach != null ? { id: selectedCoach.id, name: selectedCoach.name } : undefined }
                onChange={onCoachChanged}
                options={coachOptions}
                sx={{ width: '100%', marginBottom: '1em' }}
              />
            }
            {
              buildingOptions.length > 1 &&
                <SelectWithLabel
                  name='buildingSelect'
                  labelContent={ <Typography sx={{ fontWeight: 'bold', marginBottom: '1em' }} variant='body2'>Select Building</Typography> }
                  value={selectedBuilding}
                  options={buildingOptions}
                  width='40%'
                  onChange={handleBuildingSelect}
                />
            }

          </Box>
        </Box>
    }
  />
}
