import { useState } from 'react'
import { BannerModal } from '../../../customComponents/BannerModal'
import { DatePickerWithLabel } from '../../../customComponents/DatePickerWithLabel'
import { SelectWithLabel } from '../../../customComponents/SelectWithLabel'
import { TextArea } from '../../../customComponents/TextArea'
import { type CETAPlanAction, type GoalOwner } from '../../../../models/CETAPlanSectionModel'
import { toast } from 'react-toastify'
import { type SelectChangeEvent } from '@mui/material'

interface Props {
  ownerOptions: GoalOwner[]
  onClose: () => void
  onConfirm: (newAction: CETAPlanAction) => void
}

export const AddCETAGoalActionModal = (props: Props): JSX.Element => {
  const [owner, setOwner] = useState<GoalOwner | undefined>()
  const [targetDate, setTargetDate] = useState<Date | null>(null)
  const [summary, setSummary] = useState<string>('')
  const [showActionRequired, setShowActionRequired] = useState(false)

  const handleOwnerChange = (e: SelectChangeEvent): void => {
    const selectedOwner = props.ownerOptions.find(o => o.id === parseInt(e.target.value))
    if (selectedOwner != null) {
      setOwner(selectedOwner)
    }
  }

  const handleConfirmAddAction = (): void => {
    if (owner == null || targetDate == null || summary === '') {
      setShowActionRequired(true)
      toast.error('Please complete all required fields.')
      return
    }

    const newAction: CETAPlanAction = {
      owner,
      targetDate,
      summary
    }

    props.onConfirm(newAction)
  }

  return <BannerModal
    title='Add Action'
    confirmButtonText='Add Action'
    onClose={props.onClose}
    onConfirm={handleConfirmAddAction}
    dataTestId='addActionModal'
    bodyContent={<>
      <SelectWithLabel
        label='Owner'
        name='actionOwner'
        options={props.ownerOptions.map(o => ({ id: o.id.toString(), name: o.name }))}
        value={owner?.id.toString() ?? ''}
        required={showActionRequired && owner == null}
        showRequiredText={showActionRequired && owner == null}
        error={showActionRequired && owner == null}
        width='100%'
        onChange={handleOwnerChange}
      />

      <DatePickerWithLabel
        name='actionTargetDate'
        label='Target Date'
        value={targetDate}
        required={showActionRequired && targetDate == null}
        showRequiredText={showActionRequired && targetDate == null}
        error={showActionRequired && targetDate == null}
        dataTestId='actionTargetDate'
        onChange={setTargetDate}
        sx={{ mt: '8px' }}
      />

      <TextArea
        initialValue={summary}
        name='actionSummary'
        label='Summary'
        placeholder='What is your action?'
        required={showActionRequired && summary === ''}
        showRequiredText={showActionRequired && summary === ''}
        error={showActionRequired && summary === ''}
        onChange={(e) => { setSummary(e.target.value) }}
        sx={{ mt: '8px' }}
      />
    </>}
  />
}
