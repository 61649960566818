import { Box, Chip } from '@mui/material'
import DataTable from '../dataTable/DataTable'
import { Column, ColumnSort, ColumnType } from '../dataTable/DataTable.model'
import { ProfileImage } from './ProfileImage'
import { AccessStatus, SecurityLevel, type SelectableOption } from '../../../utils/genericTypes'
import { BlockButton } from '../buttons/BlockButton'
import { PencilIcon } from '../../../assets/svgs/PencilIcon'
import { ChecklistIcon } from '../../../assets/svgs/ChecklistIcon'
import { ArticleCheckIcon } from '../../../assets/svgs/ArticleCheckIcon'
import { ReportingIcon } from '../../../assets/svgs/ReportingIcon'
import { type PaginationProps, usePagination } from '../../../hooks/use-pagination'
import { type User } from '../../../models/User'
import { Colors } from '../../../utils/colors'
import { TargetIcon } from '../../../assets/svgs/TargetIcon'

interface UserListProps {
  hideOrganizations?: boolean
  hideBuildings?: boolean
  hideAccessLevel?: boolean
  accessLabel?: string
  users: User[]
  loading?: boolean
  editUser?: (user: User) => void
  reviewUser?: (user: User) => void
}

export const UserList = (props: UserListProps): JSX.Element => {
  const paginationProps: PaginationProps<User> = {
    rows: props.users
  }
  const pagination = usePagination(paginationProps)

  const nameColumn = new Column('firstName', 'User', ColumnType.CUSTOM)
  nameColumn.customCellGenerator = (row: User) => {
    return <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <ProfileImage name={row.firstName} imageKey={row.imageKey} />
      <Box sx={{ ml: '.3125em' }}>{row.firstName} {row.lastName}</Box>
    </Box>
  }

  const accessStatusColumn = new Column('accessStatus', `${props.accessLabel ?? 'Access Status'}`, ColumnType.CUSTOM)
  accessStatusColumn.customCellGenerator = (row: User) => {
    let color = ''
    let backgroundColor = ''
    let border = '1px solid '
    if (row.accessStatus === AccessStatus.Active) {
      color = Colors.ACTIVE_TEXT
      backgroundColor = Colors.ACTIVE_BACKGROUND
      border += Colors.ACTIVE_BORDER
    } else if (row.accessStatus === AccessStatus.Inactive) {
      color = Colors.INACTIVE_TEXT
      backgroundColor = Colors.INACTIVE_BACKGROUND
      border += Colors.INACTIVE_BORDER
    } else if (row.accessStatus === AccessStatus.Pending) {
      color = Colors.PENDING_TEXT
      backgroundColor = Colors.PENDING_BACKGROUND
      border += Colors.PENDING_BORDER
    }

    return <>
      <Chip label={row.accessStatus} sx={{ color, backgroundColor, border, fontWeight: 'bold' }} />
    </>
  }

  const buildingsColumn = new Column('buildings', 'Buildings', ColumnType.CUSTOM)
  buildingsColumn.customCellGenerator = (row: User) => {
    if (row.buildings.length <= 3) {
      return <>
        {row.buildings.map((building: SelectableOption) => {
          return <Box key={building.id}>{building.name}</Box>
        })}
      </>
    }

    return <>
      {row.buildings.slice(0, 2).map((building: SelectableOption) => {
        return <Box key={building.id}>{building.name}</Box>
      })}
      <Box>{row.buildings.length - 2} others</Box>
    </>
  }

  const organizationsColumn = new Column('organizations', 'Organizations', ColumnType.CUSTOM)
  organizationsColumn.customCellGenerator = (row: User) => {
    if (row.organizations.length <= 3) {
      return <>
        {row.organizations.map((organization: SelectableOption) => {
          return <Box key={organization.id}>{organization.name}</Box>
        })}
      </>
    }

    return <>
      {row.organizations.slice(0, 2).map((organization: SelectableOption) => {
        return <Box key={organization.id}>{organization.name}</Box>
      })}
      <Box>{row.organizations.length - 2} others</Box>
    </>
  }

  const securityLevelColumn = new Column('securityLevel', 'Access Level', ColumnType.CUSTOM)
  securityLevelColumn.customCellGenerator = (row: User) => {
    let icon = null
    if (row.securityLevel === SecurityLevel.ProjectAdmin || row.securityLevel === SecurityLevel.ProjectUser) {
      icon = <ChecklistIcon />
    } else if (row.securityLevel === SecurityLevel.RCNAdmin || row.securityLevel === SecurityLevel.RCNUser) {
      icon = <ArticleCheckIcon />
    } else if (row.securityLevel === SecurityLevel.ISDAdmin || row.securityLevel === SecurityLevel.Coach) {
      icon = <ReportingIcon />
    } else if (row.securityLevel === SecurityLevel.DistrictAdmin || row.securityLevel === SecurityLevel.DistrictUser) {
      icon = <PencilIcon />
    } else {
      icon = <TargetIcon />
    }

    let securityLevel = row.securityLevel as string
    if (securityLevel == null || securityLevel === '') {
      securityLevel = 'Custom'
    }

    return <Chip
      icon={<Box sx={{ pl: '.3125em', ' svg': { verticalAlign: 'text-bottom' } }}>{icon}</Box>}
      label={securityLevel}
      sx={{ backgroundColor: 'white', border: '1px solid #CBC8CC', fontWeight: 'bold' }}
    />
  }

  const lastChangedColumn = new Column('updatedAt', 'Last Change', ColumnType.CUSTOM)
  lastChangedColumn.customCellGenerator = (row: User) => {
    return <>
      <Box>
        {row.updatedBy}
      </Box>
      <Box>
        {new Date(row.updatedAt.toString() + 'Z').toLocaleString([], { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' })}
      </Box>
    </>
  }

  const reviewUser = (user: User): void => {
    if (props.reviewUser != null) {
      props.reviewUser(user)
    }
  }

  const editUser = (user: User): void => {
    if (props.editUser != null) {
      props.editUser(user)
    }
  }

  const editColumn = new Column('', '', ColumnType.CUSTOM)
  editColumn.sortable = false
  editColumn.customCellGenerator = (row: User) => {
    if (row.accessStatus === AccessStatus.Pending && props.reviewUser != null) {
      return <BlockButton onClick={() => { reviewUser(row) }} dataTestId='userReview'>
        Review
      </BlockButton>
    } else if (props.editUser != null) {
      return <BlockButton onClick={() => { editUser(row) }} dataTestId='userEdit'>
        View Profile
      </BlockButton>
    }

    return <></>
  }

  const getVisibleColumns = (): Column[] => {
    const columns = [nameColumn, accessStatusColumn, organizationsColumn, buildingsColumn, securityLevelColumn, lastChangedColumn, editColumn]
    const hideColumns: Column[] = []

    if (props.hideAccessLevel ?? false) {
      hideColumns.push(securityLevelColumn)
    }
    if (props.hideBuildings ?? false) {
      hideColumns.push(buildingsColumn)
    }
    if (props.hideOrganizations ?? false) {
      hideColumns.push(organizationsColumn)
    }

    return columns.filter(function (item) {
      return !hideColumns.includes(item)
    })
  }

  return <DataTable
    name='users'
    rows={pagination.internalRows}
    columns={getVisibleColumns()}
    page={pagination.page}
    totalRecords={pagination.recordCount}
    loading={props.loading}
    initialColumnSorts={[new ColumnSort('firstName')]}
    onSortChange={(col, sorts) => { pagination.onSortChange(col, sorts[0].order) }}
    onPageChange={pagination.handleChangePage}
    onRowsPerPageChange={pagination.handleChangeRowsPerPage}
    data-testid='users-list'
  />
}
