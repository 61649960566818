import { Box, Button, Card, CircularProgress, Container, Typography } from '@mui/material'
import { useAuth } from '../hooks/use-auth'
import miLaunchpadLogo from '../assets/milaunchpad.png'

export function HomePage (): JSX.Element {
  const auth = useAuth()

  if (auth.loading) {
    return <CircularProgress />
  }

  return (<Box sx={{ mt: '1.25em' }}>
    {auth.user == null && <Box>
      <Typography variant='h3' className='page-header'> Login </Typography>
      <Box sx={{ display: 'flex', mt: '1.25em' }}>
        <Card>
          <Container>
            <Typography variant='h6' component='h1'>
              MiCredentials
            </Typography>
            <Typography>
              Use your MiCredentials login information to log in to START.
            </Typography>
            <Button
              variant='contained'
              color='primary'
              onClick={auth.miCredsLogin}
              sx={{ mt: '1.25em', mb: '.625em', textTransform: 'none' }}
            >
              Login
            </Button>
          </Container>
        </Card>

        <Card sx={{ ml: '1.25em' }}>
          <Container>
            <Typography variant='h6' component='h1'>
              MiLaunchPad
            </Typography>
            <Typography>
              Use your local district login information to log in to START.
            </Typography>
            <Box>
              <img alt={'Powered by MiLaunchPad'} src={miLaunchpadLogo} />
            </Box>
            <Button
              variant='contained'
              color='primary'
              onClick={auth.miLaunchPadLogin}
              sx={{ mt: '1.25em', mb: '.625em', textTransform: 'none' }}
            >
              Login
            </Button>
          </Container>
        </Card>
      </Box>
    </Box>}

    {auth.user != null && <Box>
      <Typography variant='h4'> Welcome, {auth.user.firstName}! </Typography>

      {(auth.user.noAccount || !auth.user.isActive) && <Box>
        <Typography sx={{ color: 'red', mt: '1.25em' }}>
          You have successfully logged in, but your account is not authorized for START.
        </Typography>

        <Button
          variant='contained'
          color='primary'
          onClick={auth.logout}
          sx={{ mt: '1.25em', textTransform: 'none' }}
        >
          Logout
        </Button>
      </Box>}
    </Box>}
  </Box>)
}
