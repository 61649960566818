import { Box, FormControl, type FormControlProps, InputLabel, type SxProps } from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { type Dayjs } from 'dayjs'

interface Props {
  value: Date | null
  name: string
  label?: string
  labelContent?: JSX.Element
  FormControlProps?: FormControlProps
  disabled?: boolean
  required?: boolean
  showRequiredText?: boolean
  error?: boolean
  dataTestId?: string
  sx?: SxProps
  onChange: (newValue: Date | null) => void
  onBlur?: (e: any) => void
}

export const DatePickerWithLabel = (props: Props): JSX.Element => {
  let date = null
  if (props.value != null) {
    date = dayjs(props.value)
  }

  const handleChange = (newValue: Dayjs | null): void => {
    if (newValue == null) {
      props.onChange(null)
    } else {
      const newDate = new Date(newValue.year(), newValue.month(), newValue.date())
      props.onChange(newDate)
    }
  }

  return <>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', ...props.sx }}>
      <InputLabel id={props.name} sx={{ color: 'black' }} required={props.required === true && props.showRequiredText !== true}>
        {props.labelContent ?? props.label}
      </InputLabel>

      {props.required !== true && props.showRequiredText === true && <Box sx={{ fontWeight: 200 }}>Optional</Box>}
      {props.required === true && props.showRequiredText === true && <Box sx={{ fontWeight: 400, color: '#E00000' }}>Required</Box>}
    </Box>

    <Box>
      <FormControl id={`${props.name}FormControl`} aria-labelledby={props.name} sx={{ width: '100%' }} {...props.FormControlProps}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            data-testid={props.dataTestId}
            disableOpenPicker={props.disabled}
            disabled={props.disabled}
            value={date}
            onChange={handleChange}
            slotProps={{
              textField: {
                inputProps: {
                  'data-testid': props.dataTestId
                },
                name: props.name,
                required: props.required,
                error: props.error,
                onBlur: props.onBlur ?? (() => {})
              }
            }}
          />
        </LocalizationProvider>
      </FormControl>
    </Box>
  </>
}
