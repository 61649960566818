import { useEffect, useState } from 'react'
import { OnboardingSummary } from '../../../customComponents/organizations/OnboardingSummary'
import { CETATeamCard } from './CETATeamCard'
import { SnapshotsCard } from './SnapshotsCard'
import { type OnboardingTask } from '../../../../models/OnboardingTask'
import { Box, CircularProgress, Grid } from '@mui/material'
import { GetCETADashboardDetails, GetCETATeam, GetClassroomDetails, ToggleCETATask } from '../../../../services/CETAClassroomService'
import { useAuth } from '../../../../hooks/use-auth'
import { toast } from 'react-toastify'
import { type SnapshotDashboardModel } from '../../../../models/SnapshotDashboardModel'
import type { CETATeamMember } from '../../../../models/CETATeamMember'
import { PlanActionCard } from './PlanActionCard'
import type { CETAClassroom } from '../../../../models/CETAClassroom'
import { CETAClassroomStage } from '../../../../models/CETAClassroomStage'
import { CETAClassroomStatus } from '../../../../models/CETAClassroomStatus'
import { generatePath, useNavigate } from 'react-router'
import { AppRoutes } from '../../../Routes'
import { CETAOverviewTabs } from '../CETAOverview'

interface Props {
  classroomId: string
  snapshotsReadonly: boolean
  planLocked: boolean
}

export const CETADashboard = (props: Props): JSX.Element => {
  const { user } = useAuth()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [tasks, setTasks] = useState<OnboardingTask[]>([])
  const [isClassroomLead, setIsClassroomLead] = useState<boolean>(false)
  const [activeSnapshot, setActiveSnapshot] = useState<SnapshotDashboardModel | null>(null)
  const [completedSnapshots, setCompletedSnapshots] = useState<SnapshotDashboardModel[]>([])
  const [team, setTeam] = useState<CETATeamMember[]>([])
  const [classroom, setClassroom] = useState<CETAClassroom | null>(null)
  const nav = useNavigate()

  const fetchDashboardData = async (): Promise<void> => {
    setIsLoading(true)
    await Promise.all([
      fetchDashboardDetails(),
      fetchCETATeam(),
      fetchClassroomDetails()
    ])
    setIsLoading(false)
  }

  const fetchDashboardDetails = async (): Promise<void> => {
    const response = await GetCETADashboardDetails(props.classroomId)
    setTasks(response.tasks)
    setActiveSnapshot(response.activeSnapshot)
    setCompletedSnapshots(response.completedSnapshots)
    if (user != null) {
      setIsClassroomLead(response.classroomLeads.includes(user?.id))
    }
  }

  const fetchCETATeam = async (): Promise<void> => {
    const response = await GetCETATeam(props.classroomId)
    setTeam(response)
  }

  const fetchClassroomDetails = async (): Promise<void> => {
    setClassroom(await GetClassroomDetails(props.classroomId))
  }

  useEffect(() => {
    void fetchDashboardData()
  }, [])

  const toggleTask = async (taskKey: string): Promise<void> => {
    await ToggleCETATask(props.classroomId, taskKey)
    await fetchDashboardDetails()
  }

  const onTeamChangeSubmitted = async (): Promise<void> => {
    const response = await GetCETATeam(props.classroomId)
    setTeam(response)
    toast.success('CETA Team updated')
  }

  const viewPlan = (): void => {
    const route = generatePath(AppRoutes.CETA_OVERVIEW, { classroomId: props.classroomId, tab: CETAOverviewTabs.Plan })
    nav(route)
  }

  const continuePlan = (): void => {
    toast.warn('This feature is not yet implemented.')
  }

  const archivePlan = (): void => {
    toast.warn('This feature is not yet implemented.')
  }

  if (isLoading) {
    return <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}><CircularProgress /></Box>
  }

  return <Grid container spacing={2}>
    <Grid item>
      <PlanActionCard
        showViewPlan={!props.planLocked}
        showArchivePlan={classroom === null ? false : (classroom?.stage === CETAClassroomStage.CETAUpdateCompleted && classroom?.status === CETAClassroomStatus.Active)}
        showContinuePlan={classroom === null ? false : (classroom?.stage === CETAClassroomStage.CETAUpdateCompleted && classroom?.status === CETAClassroomStatus.Active)}
        archivePlan={archivePlan}
        continuePlan={continuePlan}
        viewPlan={viewPlan}
      />
      <SnapshotsCard
        readonly={props.snapshotsReadonly}
        classroomId={props.classroomId}
        isLead={isClassroomLead}
        activeSnapshot={activeSnapshot}
        completedSnapshots={completedSnapshots}
        refreshData={fetchDashboardData}
      />
    </Grid>

    <Grid item>
      <OnboardingSummary
        readonly={!isClassroomLead}
        onboardingComplete={false}
        tasks={tasks}
        resources={[]}
        title='CETA Progress'
        subTitle='Track your CETA progress here:'
        minWidth='300px'
        borderColor='#CBC8CC'
        backgroundColor='#F3F3F380'
        titleColor='#021E40'
        toggleTask={toggleTask}
        bottomContent={<></>}
      />
    </Grid>

    <Grid item>
      <CETATeamCard readonly={!isClassroomLead} team={team} classroomId={props.classroomId} onTeamChangeSubmitted={onTeamChangeSubmitted}/>
    </Grid>
  </Grid>
}
