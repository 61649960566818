import { IconButton, type SxProps, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog, { type DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import CloseIcon from '@mui/icons-material/Close'

export type ConfirmationDialogProps = DialogProps & {
  open: boolean
  titleContent?: JSX.Element
  bodyContent?: React.ReactNode
  maxWidth?: string
  width?: string
  cancelButtonText?: string
  confirmButtonText?: string
  centerButtonText?: string
  hideCancelButton?: boolean
  isUpdateDisabled?: boolean
  buttonClassName?: string
  cancelButtonClassName?: string
  confirmButtonClassName?: string
  hideXtoClose?: boolean
  centerTitle?: boolean
  noButtons?: boolean
  position?: 'fixed' | 'absolute' | 'relative' | 'static' | 'sticky' | undefined
  containerPosition?: 'fixed' | 'absolute' | 'relative' | 'static' | 'sticky' | undefined
  bottom?: string | number
  margin?: string | number
  contentSx?: SxProps
  onClose: () => void
  onCancel?: () => void
  onConfirm: () => void
  onCenterButtonClick?: () => void
}

export const Modal = (props: ConfirmationDialogProps): JSX.Element => {
  function onDialogClose (event: any, reason: string): void {
    if (reason !== 'backdropClick') {
      props.onClose()
    }
  }

  const {
    onClose, onCancel, onConfirm, onCenterButtonClick, bodyContent, title, open, titleContent, maxWidth,
    cancelButtonText, confirmButtonText, centerButtonText, hideCancelButton,
    isUpdateDisabled, buttonClassName, cancelButtonClassName, confirmButtonClassName, hideXtoClose, centerTitle, noButtons, contentSx,
    containerPosition, position, bottom, margin, width,
    ...other
  } =
    props
  const content = bodyContent !== null ? bodyContent : 'Are you sure you\'d like to perform this action?'
  const confirmationTitle = props.title ?? 'Confirm'

  return (
    <Dialog
      sx={{ position: containerPosition }}
      maxWidth={maxWidth ?? 'xs'}
      PaperProps={{ sx: { position, width, bottom, margin, overflow: 'hidden', '&&': { maxWidth } } }}
      open={open}
      onClose={onDialogClose}
      {...other}
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: centerTitle === true ? 'center' : 'space-between' }} data-testid='modal-title'>
        {titleContent != null
          ? titleContent
          : <Typography sx={{ fontWeight: 'bold', alignSelf: 'center', fontSize: '1em' }}>{confirmationTitle}</Typography>
        }

        {(props.hideXtoClose == null || !props.hideXtoClose) &&
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              width: 40,
              height: 40,
              color: (theme) => theme.palette.grey[900]
            }}
          >
            <CloseIcon sx={{ width: '40px', height: '40px' }} />
          </IconButton>
        }
      </DialogTitle>

      <DialogContent data-testid='modal-content' sx={ { ...contentSx, borderBottom: '1px solid #CBC8CC' }}>
        {content}
      </DialogContent>

      {(noButtons == null || !noButtons) &&
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          {hideCancelButton !== true &&
            <Button
              data-testid='cancelButton'
              className={cancelButtonClassName ?? buttonClassName ?? 'modal-button'}
              variant='outlined'
              color='inherit'
              sx={{ fontWeight: 'bold' }}
              onClick={onCancel ?? onClose}
            >
              {cancelButtonText ?? 'Cancel'}
            </Button>
          }

          {hideCancelButton === true && <span />}

          {props.onCenterButtonClick != null &&
            <Button
              data-testid='centerButton'
              onClick={props.onCenterButtonClick}
              variant='contained'
              className={buttonClassName ?? 'modal-button'}
            >
              {centerButtonText ?? 'Cancel'}
            </Button>
          }

          <Button
            data-testid='updateButton'
            onClick={onConfirm}
            variant='contained'
            disabled={props.isUpdateDisabled ?? false}
            className={confirmButtonClassName ?? buttonClassName ?? 'modal-confirm-button'}
          >
            {confirmButtonText ?? 'Update'}
          </Button>
        </DialogActions>
      }
    </Dialog>
  )
}
