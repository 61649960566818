import { type Response, sendGet, sendPost, sendPostFormData } from '../hooks/use-fetch'
import { type BuildingEditModel } from '../models/BuildingEditModel'
import { type EEMOrganization } from '../models/EEMOrganization'
import { type OrganizationDetailsModel } from '../models/OrganizationDetailsModel'
import { type OrganizationListModel } from '../models/OrganizationListModel'
import { type OrganizationOnboardingDetails } from '../models/OrganizationOnboardingDetails'
import { type OrganizationSummaryModel } from '../models/OrganizationSummaryModel'
import { type User } from '../models/User'
import { type OrgSearchValues } from '../pages/pageComponents/organizations/OrganizationSearch'
import { type ActiveStatus, type OrganizationStatus, type SelectableOption } from '../utils/genericTypes'

export async function GetOrganization (orgId: string): Promise<OrganizationDetailsModel> {
  const { response } = await sendGet(`Organization/GetDetails?orgId=${orgId}`)
  return response
}

export async function GetBuildingDetails (buildingId: string): Promise<BuildingEditModel> {
  const { response } = await sendGet(`Organization/GetBuildingDetails?buildingId=${buildingId}`)
  return response
}

export async function GetOrgSummary (orgId: string): Promise<OrganizationSummaryModel> {
  const { response } = await sendGet(`Organization/GetSummary?orgId=${orgId}`)
  return response
}

export async function GetAdminContacts (orgId: string): Promise<User[]> {
  const { response } = await sendGet(`Organization/GetAdminContacts?orgId=${orgId}`)
  return response
}

export async function GetOrgList (searchValues: OrgSearchValues): Promise<OrganizationListModel[]> {
  const { response } = await sendPost('Organization/GetList', searchValues)
  return response
}

export async function GetAvailableISDsFromEEM (): Promise<SelectableOption[]> {
  const { response } = await sendGet('Organization/GetAvailableISDsFromEEM')
  return response
}

export async function GetAvailableBuildingsFromEEM (districtId: string): Promise<EEMOrganization[]> {
  const { response } = await sendGet(`Organization/GetAvailableBuildingsFromEEM?districtId=${districtId}`)
  return response
}

export async function GetAvailableDistrictsFromEEM (isdId: string): Promise<EEMOrganization[]> {
  const { response } = await sendGet(`Organization/GetAvailableDistrictsFromEEM?isdId=${isdId}`)
  return response
}

export async function AddBuilding (districtId: string, eemOrgId: string): Promise<void> {
  await sendPost('Organization/AddBuilding', { districtId, eemOrgId })
}

export async function AddDistrict (parentOrgId: string, eemOrgId: string): Promise<void> {
  await sendPost('Organization/AddDistrict', { parentOrgId, eemOrgId })
}

export async function AddRCN (parentOrgId: string, rcnName: string, isdEEMIds: number[]): Promise<Response> {
  return await sendPost('Organization/AddRCN', { parentOrgId, rcnName, isdEEMIds })
}

export async function GetOnboardingDetails (orgId: string): Promise<OrganizationOnboardingDetails> {
  const { response } = await sendGet(`Organization/GetOnboardingDetails?orgId=${orgId}`)
  return response
}

export async function ToggleOnboardingTask (orgId: string, taskKey: string): Promise<void> {
  await sendPost('Organization/ToggleOnboardingTask', { orgId, taskKey })
}

export async function VerifyOnboarding (orgId: string): Promise<void> {
  await sendPost('Organization/VerifyOnboarding', { orgId })
}

export async function UpdateStatus (orgId: string, status: OrganizationStatus): Promise<void> {
  await sendPost('Organization/UpdateStatus', { orgId, status })
}

export async function UpdateBuildingStatus (buildingId: string, status: ActiveStatus): Promise<void> {
  await sendPost('Organization/UpdateBuildingStatus', { buildingId, status })
}

export async function UploadBuildingLogo (buildingId: string, file: File): Promise<string> {
  const fileData = new FormData()
  fileData.append('file', file)
  fileData.append('buildingId', buildingId)
  const { response: s3key } = await sendPostFormData('Organization/UploadBuildingLogo', fileData)
  return s3key
}

export async function GetOrganizationsForUser (): Promise<OrganizationListModel[]> {
  const { response } = await sendGet('Organization/GetOrganizationsForUser')
  return response
}
