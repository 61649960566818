import { Grid } from '@mui/material'
import { NeedsAttentionCard } from './needsAttention/NeedsAttentionCard'
import { useEffect, useState } from 'react'
import { type NeedsAttentionModel } from '../../../models/NeedsAttentionModel'
import { GetNeedsAttention } from '../../../services/NeedsAttentionService'
import { AdminContactCard } from './users/AdminContactCard'
import { type User } from '../../../models/User'
import { GetAdminContacts, GetOnboardingDetails, ToggleOnboardingTask, VerifyOnboarding } from '../../../services/OrganizationService'
import { OnboardingSummary } from '../../customComponents/organizations/OnboardingSummary'
import { OrganizationType } from '../../../utils/genericTypes'
import { type OrganizationDetailsModel } from '../../../models/OrganizationDetailsModel'
import { OnboardingTaskKey, type OnboardingTask } from '../../../models/OnboardingTask'
import { Modal } from '../../customComponents/Modal'

interface Props {
  primaryOrg: OrganizationDetailsModel
  onboardingVerified: () => void
}

export const OrganizationOverview = (props: Props): JSX.Element => {
  const [needAttentionEntities, setNeedAttentionEntities] = useState<NeedsAttentionModel[]>([])
  const [adminContacts, setAdminContacts] = useState<User[]>([])
  const [onboardingTasks, setOnboardingTasks] = useState<OnboardingTask[]>([])
  const [onboardingVerified, setOnboardingVerified] = useState<boolean>(false)
  const [isConfirmingAdminContacts, setIsConfirmingAdminContacts] = useState<boolean>(false)
  const [checkedTaskKey, setCheckedTaskKey] = useState<OnboardingTaskKey | null>(null)

  const fetchAdminContacts = async (): Promise<void> => {
    const adminContacts = await GetAdminContacts(props.primaryOrg.id.toString())
    setAdminContacts(adminContacts)
  }

  const fetchOnboardingDetails = async (): Promise<void> => {
    const onboarding = await GetOnboardingDetails(props.primaryOrg.id.toString())
    setOnboardingTasks(onboarding.tasks)
    setOnboardingVerified(onboarding.onboardingVerified)
  }

  const needsAttention = async (): Promise<void> => {
    const needAttention = await GetNeedsAttention(props.primaryOrg.id.toString())
    setNeedAttentionEntities(needAttention)
  }

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      await Promise.all([
        fetchAdminContacts(), fetchOnboardingDetails(), needsAttention()
      ])
    }

    void fetchData()
  }, [props.primaryOrg.id])

  const verifyOnboarding = async (): Promise<void> => {
    await VerifyOnboarding(props.primaryOrg.id.toString())
    props.onboardingVerified()
    await fetchOnboardingDetails()
  }

  const toggleTask = async (taskKey: string): Promise<void> => {
    if (taskKey === OnboardingTaskKey.AdminContacts &&
      adminContacts.length === 0 &&
      onboardingTasks.find(t => t.key === OnboardingTaskKey.AdminContacts)?.completed === false
    ) {
      setIsConfirmingAdminContacts(true)
      setCheckedTaskKey(taskKey)
      return
    }

    await confirmToggleTask(taskKey)
  }

  const confirmToggleTask = async (taskKey: string | null): Promise<void> => {
    if (taskKey == null) {
      return
    }

    await ToggleOnboardingTask(props.primaryOrg.id.toString(), taskKey)
    await fetchOnboardingDetails()
    setIsConfirmingAdminContacts(false)
  }

  return <Grid container spacing={2}>
    <Grid item xs={4}>
      <AdminContactCard primaryOrg={props.primaryOrg} adminContacts={adminContacts} onSave={fetchAdminContacts} />
    </Grid>
    <Grid item xs={4} sx={{ minWidth: '20em' }}>
      <NeedsAttentionCard primaryOrg={props.primaryOrg} needAttentionEntities={needAttentionEntities} />
    </Grid>
    <Grid item xs={4} sx={{ minWidth: '20em' }}>
      {props.primaryOrg.type !== OrganizationType.Project &&
        <OnboardingSummary
          onboardingComplete={onboardingVerified}
          readonly={onboardingVerified}
          tasks={onboardingTasks}
          resources={[]}
          completeOnboardingText='Verify Onboarding'
          toggleTask={toggleTask}
          verifyCompletion={verifyOnboarding}
        />}
    </Grid>

    {isConfirmingAdminContacts &&
      <Modal
        open={true}
        title='Missing Admin Contacts'
        cancelButtonText='No, Cancel'
        confirmButtonText='Yes, Continue'
        bodyContent='You have not added any admin contacts. Are you sure you want to continue?'
        onClose={() => { setIsConfirmingAdminContacts(false) }}
        onConfirm={async () => { await confirmToggleTask(checkedTaskKey) }}
      />
    }
  </Grid>
}
