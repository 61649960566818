import { Box, Grid, Typography } from '@mui/material'
import { SelectWithLabel } from '../../../customComponents/SelectWithLabel'
import { OrganizationType, SecurityLevel, type UserPermission } from '../../../../utils/genericTypes'
import { FunctionCheckList } from './FunctionChecklist'
import { LabelWithToolTip } from '../../../customComponents/LabelWithTooltip'

interface AuthorizedFunctionViewProps {
  selectBoxTitle: string
  requestDetails?: string
  securityLevel?: SecurityLevel
  permissionOptions: UserPermission[]
  selectedFunctions: string[]
  selectedOrgType?: OrganizationType
  onAccessChanged: (sl: SecurityLevel) => void
  onFunctionValuesChanged: (newValue: string[]) => void
}

export function AuthorizedFunctionView (props: AuthorizedFunctionViewProps): JSX.Element {
  const securityLevelOptions = []
  if (props.selectedOrgType === OrganizationType.Project) {
    securityLevelOptions.push({ id: SecurityLevel.ProjectAdmin, name: SecurityLevel.ProjectAdmin })
    securityLevelOptions.push({ id: SecurityLevel.ProjectUser, name: SecurityLevel.ProjectUser })
  } else if (props.selectedOrgType === OrganizationType.RCN) {
    securityLevelOptions.push({ id: SecurityLevel.RCNAdmin, name: SecurityLevel.RCNAdmin })
    securityLevelOptions.push({ id: SecurityLevel.RCNUser, name: SecurityLevel.RCNUser })
  } else if (props.selectedOrgType === OrganizationType.ISD) {
    securityLevelOptions.push({ id: SecurityLevel.ISDAdmin, name: SecurityLevel.ISDAdmin })
    securityLevelOptions.push({ id: SecurityLevel.Coach, name: SecurityLevel.Coach })
  } else if (props.selectedOrgType === OrganizationType.District) {
    securityLevelOptions.push({ id: SecurityLevel.DistrictAdmin, name: SecurityLevel.DistrictAdmin })
    securityLevelOptions.push({ id: SecurityLevel.DistrictUser, name: SecurityLevel.DistrictUser })
  }

  return <Box>
    <Grid container spacing={5}>
      <Grid item xs={props.requestDetails == null ? 12 : 6}>
        <SelectWithLabel
          name='securityLevel'
          label={props.selectBoxTitle}
          value={props.securityLevel ?? ''}
          options={securityLevelOptions}
          minWidth='10em'
          width='100%'
          onChange={(e) => { props.onAccessChanged(e.target.value as SecurityLevel) }}
        />
      </Grid>

      {props.requestDetails != null &&
        <Grid item xs={6}>
          <Typography sx={{ fontWeight: '600' }}>
            Request Details
          </Typography>
          <Typography variant='body1'>
            {props.requestDetails}
          </Typography>
        </Grid>
      }
    </Grid>

    {props.securityLevel != null && <Box sx={{ pt: '1.5em' }}>
      <FunctionCheckList
        functionOptions={props.permissionOptions}
        selectedFunctions={props.selectedFunctions}
        setSelectedFunctions={props.onFunctionValuesChanged}
        headers={[
          <LabelWithToolTip
            labelText='Authorized Functions'
            sx={{ whiteSpace: 'break-spaces', color: 'black', overflowWrap: 'break-word', fontWeight: '600' }}
          />,
          <LabelWithToolTip
            labelText='View'
            sx={{ whiteSpace: 'break-spaces', color: 'black', overflowWrap: 'break-word', fontWeight: '600', ml: '3.75em' }}
          />,
          <LabelWithToolTip
            labelText='Edit'
            sx={{ whiteSpace: 'break-spaces', color: 'black', overflowWrap: 'break-word', fontWeight: '600', ml: '3.75em' }}
          />
        ]}
      />
    </Box>}
  </Box>
}
