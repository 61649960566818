import { Accordion, AccordionSummary, Box, Typography, AccordionDetails, Link, IconButton, Menu, MenuItem } from '@mui/material'
import { SchoolIcon } from '../../../../assets/svgs/SchoolIcon'
import { WarningCard } from '../../../customComponents/WarningCard'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { type OrganizationOverviewModel } from '../../../../models/OrganizationOverviewModel'
import { generatePath, useNavigate } from 'react-router'
import { AppRoutes } from '../../../Routes'
import { OrgTabs } from '../OrganizationDetails'
import { OrganizationType } from '../../../../utils/genericTypes'
import { UserDetailsView } from '../../../customComponents/user/DetailsView'
import { useContext, useRef, useState } from 'react'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { BreadcrumbContext, SetBreadcrumbContext } from '../../../../contextProviders/BreadcrumbProvider'
import { type OrganizationDetailsModel } from '../../../../models/OrganizationDetailsModel'

interface Props {
  primaryOrg: OrganizationDetailsModel
  organization: OrganizationOverviewModel
}

export const OrganizationAccordion = (props: Props): JSX.Element => {
  const nav = useNavigate()
  const breadcrumbs = useContext(BreadcrumbContext)
  const setBreadcrumbs = useContext(SetBreadcrumbContext)

  const [openMenu, setOpenMenu] = useState<boolean>(false)
  const anchorEl = useRef<HTMLButtonElement>(null)

  const handleConfigClick = (): void => {
    const currentRoute = generatePath(AppRoutes.ORGANIZATION_DETAILS, { id: props.primaryOrg.id.toString(), tab: OrgTabs.Overview })
    setBreadcrumbs([...breadcrumbs, { name: props.primaryOrg.name, route: currentRoute }])

    const configRoute = generatePath(AppRoutes.ORGANIZATION_DETAILS, { id: props.organization.id.toString(), tab: OrgTabs.Configuration })
    nav(configRoute)
  }

  const goToTab = (): void => {
    const currentRoute = generatePath(AppRoutes.ORGANIZATION_DETAILS, { id: props.primaryOrg.id.toString(), tab: OrgTabs.Overview })
    setBreadcrumbs([...breadcrumbs, { name: props.primaryOrg.name, route: currentRoute }])

    let tab = OrgTabs.Users
    if (props.organization.adminContacts.length === 0) {
      tab = OrgTabs.Overview
    } else if (!props.organization.miDXConnectionVerified) {
      tab = OrgTabs.Configuration
    }
    const route = generatePath(AppRoutes.ORGANIZATION_DETAILS, { id: props.organization.id.toString(), tab })
    nav(route)
    setOpenMenu(false)
  }

  return <Accordion>
    <AccordionSummary
      data-testid='organizationAccordionSummary'
      expandIcon={
        <KeyboardArrowDownIcon sx={{ color: 'white', bgcolor: '#726F74', width: '1.125em', height: '1.125em', borderRadius: '50%' }} />
      }
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box><SchoolIcon /></Box>
        <Typography sx={{ fontSize: '1.25em', ml: '1em' }}>{props.organization.name}</Typography>
        <WarningAmberIcon sx={{ mx: '.5em' }} />
      </Box>
    </AccordionSummary>

    <AccordionDetails>
      {props.organization.adminContacts.length === 0 &&
        <WarningCard message='This organization does not have an active Administrator Contact.' />}

      {(props.organization.type === OrganizationType.ISD || props.organization.type === OrganizationType.District) &&
        !props.organization.miDXConnectionVerified &&
          <WarningCard
            sx={{ mt: '1em' }}
            message={<>This organization is not currently configured to connect to the Michigan Data Exchange. Click <Link sx={{ cursor: 'pointer', color: '#156ABC' }} onClick={handleConfigClick} data-testid='configLink'>here</Link> for more details.</>}
          />
      }

      {props.organization.adminContacts.map((contact, contactIndex) => (
        <Box key={contactIndex} data-testid='adminContactItem' sx={{ my: '.625em' }}>
          <UserDetailsView user={contact} allowEdit={false} updateUser={() => {}} />
        </Box>
      ))}

      <IconButton aria-label="menu" data-testid="ellipsesMenu" ref={anchorEl} onClick={() => { setOpenMenu(true) }} sx={{ float: 'right' }}>
        <MoreHorizIcon fontSize='large' sx={{ fill: 'black' }} />
      </IconButton>

      <Menu open={openMenu} anchorEl={anchorEl.current} onClose={() => { setOpenMenu(false) }}>
        <MenuItem
          data-testid='menuItemView'
          onClick={goToTab}
        >
          View
        </MenuItem>
      </Menu>
    </AccordionDetails>
  </Accordion>
}
