import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { EditOutlined } from '@mui/icons-material'
import { TemplateInstructionsEditModal } from './templates/TemplateInstructionsEditModal'
import { getCETATemplateInstructions, saveCETATemplateInstructions } from '../../../services/CETATemplateService'
import { CETAInstructionsKey, type CETATemplateInstructions } from '../../../models/CETATemplateInstructions'

export const ProjectConfiguration = (): JSX.Element => {
  const [instructions, setInstructions] = useState<CETATemplateInstructions | null>(null)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [currentText, setCurrentText] = useState<string>('')
  const [currentKey, setCurrentKey] = useState<string>('')

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const response = await getCETATemplateInstructions()
      setInstructions(response)
    }

    void fetchData()
  }, [])

  const handleEdit = (key: string, text: string): void => {
    setCurrentKey(key)
    setCurrentText(text)
    setIsEditing(true)
  }

  const handleSubmit = async (newText: string): Promise<void> => {
    if (instructions == null) {
      return
    }
    const tmpInstructions = { ...instructions }

    switch (currentKey) {
      case CETAInstructionsKey.AboutTheCETA:
        tmpInstructions.aboutTheCETA = newText
        break
      case CETAInstructionsKey.PlanningInstructions:
        tmpInstructions.planning = newText
        break
      case CETAInstructionsKey.ReconcileInstructions:
        tmpInstructions.reconcile = newText
        break
      case CETAInstructionsKey.CETACodingSystem:
        tmpInstructions.codingSystem = newText
        break
      case CETAInstructionsKey.ProgressUpdateInstructions:
        tmpInstructions.progressUpdate = newText
        break
      case CETAInstructionsKey.CETABenefits:
        tmpInstructions.cetaBenefits = newText
        break
      case CETAInstructionsKey.RecommendedCollabForCETA:
        tmpInstructions.recommendedCollabForCETA = newText
        break
      case CETAInstructionsKey.ImplementingCETA:
        tmpInstructions.implementingCETA = newText
        break
    }

    setInstructions(tmpInstructions)
    await saveCETATemplateInstructions(tmpInstructions)
  }

  if (instructions == null) {
    return <CircularProgress />
  }

  return <>
    <Typography variant='h4' sx={{ fontSize: '1.25em' }}>CETA Descriptions</Typography>

    <Grid container spacing={6}>
      <Grid item xs={6}>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: '3em' }}>
          <Typography variant='h5' sx={{ fontSize: '1em', fontWeight: '600' }}>{CETAInstructionsKey.AboutTheCETA}</Typography>
          <EditOutlined
            sx={{ fill: 'black', cursor: 'pointer', ml: '.5em' }}
            data-testid='AboutTheCETA-edit'
            onClick={() => { handleEdit(CETAInstructionsKey.AboutTheCETA, instructions.aboutTheCETA) }}
          />
        </Box>
        <div className='ql-editor' dangerouslySetInnerHTML={{ __html: instructions.aboutTheCETA }} data-testid='AboutTheCETA-text' />

        <Box sx={{ display: 'flex', alignItems: 'center', mt: '3em' }}>
          <Typography variant='h5' sx={{ fontSize: '1em', fontWeight: '600' }}>{CETAInstructionsKey.CETABenefits}</Typography>
          <EditOutlined
            sx={{ fill: 'black', cursor: 'pointer', ml: '.5em' }}
            data-testid='CETABenefits-edit'
            onClick={() => { handleEdit(CETAInstructionsKey.CETABenefits, instructions.cetaBenefits) }}
          />
        </Box>
        <div className='ql-editor' dangerouslySetInnerHTML={{ __html: instructions.cetaBenefits }} data-testid='CETABenefits-text' />

        <Box sx={{ display: 'flex', alignItems: 'center', mt: '3em' }}>
          <Typography variant='h5' sx={{ fontSize: '1em', fontWeight: '600' }}>{CETAInstructionsKey.RecommendedCollabForCETA}</Typography>
          <EditOutlined
            sx={{ fill: 'black', cursor: 'pointer', ml: '.5em' }}
            data-testid='RecommendedCollabForCETA-edit'
            onClick={() => { handleEdit(CETAInstructionsKey.RecommendedCollabForCETA, instructions.recommendedCollabForCETA) }}
          />
        </Box>
        <div className='ql-editor' dangerouslySetInnerHTML={{ __html: instructions.recommendedCollabForCETA }} data-testid='RecommendedCollabForCETA-text' />

        <Box sx={{ display: 'flex', alignItems: 'center', mt: '3em' }}>
          <Typography variant='h5' sx={{ fontSize: '1em', fontWeight: '600' }}>{CETAInstructionsKey.ImplementingCETA}</Typography>
          <EditOutlined
            sx={{ fill: 'black', cursor: 'pointer', ml: '.5em' }}
            data-testid='ImplementingCETA-edit'
            onClick={() => { handleEdit(CETAInstructionsKey.ImplementingCETA, instructions.implementingCETA) }}
          />
        </Box>
        <div className='ql-editor' dangerouslySetInnerHTML={{ __html: instructions.implementingCETA }} data-testid='ImplementingCETA-text' />
      </Grid>

      <Grid item xs={6}>

        <Box sx={{ display: 'flex', alignItems: 'center', mt: '3em' }}>
          <Typography variant='h5' sx={{ fontSize: '1em', fontWeight: '600' }}>{CETAInstructionsKey.PlanningInstructions}</Typography>
          <EditOutlined
            sx={{ fill: 'black', cursor: 'pointer', ml: '.5em' }}
            data-testid='PlanningInstructions-edit'
            onClick={() => { handleEdit(CETAInstructionsKey.PlanningInstructions, instructions.planning) }}
          />
        </Box>
        <div className='ql-editor' dangerouslySetInnerHTML={{ __html: instructions.planning }} data-testid='PlanningInstructions-text' />

        <Box sx={{ display: 'flex', alignItems: 'center', mt: '3em' }}>
          <Typography variant='h5' sx={{ fontSize: '1em', fontWeight: '600' }}>{CETAInstructionsKey.ReconcileInstructions}</Typography>
          <EditOutlined
            sx={{ fill: 'black', cursor: 'pointer', ml: '.5em' }}
            data-testid='ReconcileInstructions-edit'
            onClick={() => { handleEdit(CETAInstructionsKey.ReconcileInstructions, instructions.reconcile) }}
          />
        </Box>
        <div className='ql-editor' dangerouslySetInnerHTML={{ __html: instructions.reconcile }} data-testid='ReconcileInstructions-text' />

        <Box sx={{ display: 'flex', alignItems: 'center', mt: '3em' }}>
          <Typography variant='h5' sx={{ fontSize: '1em', fontWeight: '600' }}>{CETAInstructionsKey.CETACodingSystem}</Typography>
          <EditOutlined
            sx={{ fill: 'black', cursor: 'pointer', ml: '.5em' }}
            data-testid='CETACodingSystem-edit'
            onClick={() => { handleEdit(CETAInstructionsKey.CETACodingSystem, instructions.codingSystem) }}
          />
        </Box>
        <div className='ql-editor' dangerouslySetInnerHTML={{ __html: instructions.codingSystem }} data-testid='CETACodingSystem-text' />

        <Box sx={{ display: 'flex', alignItems: 'center', mt: '3em' }}>
          <Typography variant='h5' sx={{ fontSize: '1em', fontWeight: '600' }}>{CETAInstructionsKey.ProgressUpdateInstructions}</Typography>
          <EditOutlined
            sx={{ fill: 'black', cursor: 'pointer', ml: '.5em' }}
            data-testid='ProgressUpdateInstructions-edit'
            onClick={() => { handleEdit(CETAInstructionsKey.ProgressUpdateInstructions, instructions.progressUpdate) }}
          />
        </Box>
        <div className='ql-editor' dangerouslySetInnerHTML={{ __html: instructions.progressUpdate }} data-testid='ProgressUpdateInstructions-text' />
      </Grid>
    </Grid>

    {isEditing && <TemplateInstructionsEditModal
      textKey={currentKey}
      initialText={currentText}
      onClose={() => { setIsEditing(false) }}
      onSubmit={handleSubmit}
    />}
  </>
}
