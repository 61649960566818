import { Box, Grid, IconButton, Typography } from '@mui/material'
import { type User } from '../../../models/User'
import { ProfileImage } from './ProfileImage'
import { useState } from 'react'
import { UserDetailsView } from './DetailsView'
import { EditOutlined } from '@mui/icons-material'
import { UploadProfileImage } from '../../../services/UserManagementService'
import { BlueButton } from '../buttons/BlueButton'
import { useAuth } from '../../../hooks/use-auth'
import { AuthFunctions, hasPermission } from '../../../utils/permissions'
import { AccessStatus } from '../../../utils/genericTypes'

interface UserDetailsProps {
  imageKey: string | undefined
  detailName: string
  isOrganizationView: boolean
  infoDisplay?: string
  users: User[]
  isEditable: boolean
  maxWidth?: string
  onUpdate?: (updatedUser: User) => void
  onSave?: (newId: number) => void
}

export const UserDetails = (props: UserDetailsProps): JSX.Element => {
  const [tmpKey, setTmpKey] = useState<string | null>(null)
  const user = props.users[0]
  const auth = useAuth()

  const canImpersonate = props.isOrganizationView
    ? false
    : auth.user != null &&
    !auth.user.isImpersonating &&
    user.id > 0 &&
    user.accessStatus === AccessStatus.Active &&
    auth.user.id !== user.id &&
    hasPermission(auth.user, AuthFunctions.IMPERSONATION)

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = e.target.files?.item(0)
    if (file == null) return
    if (!props.isOrganizationView) {
      const s3key = await UploadProfileImage(user.id.toString(), file)
      setTmpKey(s3key)
    }
  }

  return <Box sx={{ maxWidth: `${props.maxWidth ?? '315px'}`, border: '1px solid #CBC8CC', backgroundColor: '#F3F3F380' }}>
    {<Box sx={{ display: 'flex', justifyContent: 'center', margin: '.625em' }} data-testid='profileImageSection'>
      <Box sx={{ position: 'relative' }}>
        <ProfileImage name={props.detailName} imageKey={tmpKey ?? props.imageKey} height='5.5em' width='5.5em' />

        {props.isEditable && !props.isOrganizationView &&
          <IconButton
            component='label'
            aria-label='Change Profile Image'
            role=''
            sx={{ position: 'absolute', right: 0, bottom: 0, backgroundColor: '#D9D9D9', ':hover': { backgroundColor: '#A7A5A5 !important' } }}
          >
            <EditOutlined sx={{ fill: 'black' }} />
            <input hidden type="file" name='file' onChange={handleFileSelect} />
          </IconButton>}
      </Box>
    </Box>}

    <Box sx={{ display: 'flex', justifyContent: 'center', margin: '.625em' }}>
      <Grid container sx={{ justifyContent: 'center' }}>
        <Grid item sx={{ marginBottom: '1em', textAlign: 'center' }}>
          <Typography sx={{ fontSize: '1.375em', fontWeight: '600' }}>
            { props.infoDisplay ?? 'User Information' }
          </Typography>

          {canImpersonate && <BlueButton onClick={async () => { await auth.impersonate(user.id) }} dataTestId='impersonate'>
            Impersonate
          </BlueButton>}
        </Grid>

        {props.users.map((userDetail) =>
          <UserDetailsView key={`userdetailkey-${userDetail.id}`} user={userDetail} allowEdit={props.isEditable} updateUser={props.onUpdate} />
        )}
      </Grid>
    </Box>
  </Box>
}
