import { Box, CircularProgress, Typography } from '@mui/material'
import { UserDetails } from '../../customComponents/user/Details'
import MUIAutoComplete from '../../customComponents/MUIAutoComplete'
import type { User } from '../../../models/User'
import { type SyntheticEvent, useState, useEffect } from 'react'
import type { SelectableOption, ValidationResult } from '../../../utils/genericTypes'
import { GetUserDetails } from '../../../services/UserManagementService'
import { AddCETAClassroomMember, RemoveCETAClassroomMember, GetPossibleTeamMembers, UpdateCETAClassroomMember } from '../../../services/CETAClassroomService'
import { toast } from 'react-toastify'
import { SquareSwitch } from '../../customComponents/SquareSwitch'
import type { CETATeamMember } from '../../../models/CETATeamMember'
import { BlueButton } from '../../customComponents/buttons/BlueButton'
import { BlockButton } from '../../customComponents/buttons/BlockButton'
import type { CETAClassroomMemberRequest } from '../../../models/CETAClassroomMemberRequest'
import { Modal } from '../../customComponents/Modal'
import { BannerModal } from '../../customComponents/BannerModal'

interface Props {
  classroomId: string
  editMode: boolean
  memberToEdit: CETATeamMember | undefined
  closeModal: () => void
  onSubmit: () => void
}

export const AddTeamMemberModal = (props: Props): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true)
  const [memberOptions, setMemberOptions] = useState<SelectableOption[]>([])
  const [selectedMember, setSelectedMember] = useState<User>()
  const [memberIsLead, setMemberIsLead] = useState<boolean>(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false)
  const [deleteMemberInfo, setDeleteMemberInfo] = useState<CETAClassroomMemberRequest | null>(null)

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (props.editMode && props.memberToEdit != null) {
        setIsLoading(true)
        const ddlOptions: SelectableOption[] = [{ id: props.memberToEdit.userId, name: `${props.memberToEdit.firstName} ${props.memberToEdit.lastName}` }]
        setMemberOptions(ddlOptions)
        await fetchTeamMember()
        setMemberIsLead(props.memberToEdit.isLead)
        setIsLoading(false)
      } else {
        setIsLoading(true)
        const ddlOptions: SelectableOption[] = await GetPossibleTeamMembers(props.classroomId)
        setMemberOptions(ddlOptions)
        setIsLoading(false)
      }
    }

    void fetchData()
  }, [])

  const fetchTeamMember = async (): Promise<void> => {
    if (props.memberToEdit != null) {
      const selectedPerson = await GetUserDetails(props.memberToEdit.userId.toString())
      setSelectedMember(selectedPerson)
    }
  }

  const handleSubmit = async (): Promise<void> => {
    await handleSave(false)
  }

  const updateMember = async (): Promise<void> => {
    await handleSave(false)
  }

  const removeAccess = async (): Promise<void> => {
    await handleSave(true)
  }

  const handleSave = async (isDelete: boolean): Promise<void> => {
    if (selectedMember?.id != null) {
      let response: ValidationResult
      const memberInfo: CETAClassroomMemberRequest = { classroomId: parseInt(props.classroomId), memberUserId: selectedMember.id, isLead: memberIsLead }

      if (isDelete) {
        setDeleteMemberInfo(memberInfo)
        setShowDeleteConfirmation(true)
        return
      } else if (props.editMode) {
        response = await UpdateCETAClassroomMember(memberInfo)
      } else {
        response = await AddCETAClassroomMember(memberInfo)
      }
      if (response.errors.length > 0) {
        response.errors.forEach((error) => {
          toast.error(error)
        })
      } else {
        props.onSubmit()
      }
    } else {
      toast.error('No member selected.')
    }
  }

  const handleConfirmDelete = async (): Promise<void> => {
    if (deleteMemberInfo !== null) {
      const response: ValidationResult = await RemoveCETAClassroomMember(deleteMemberInfo)
      if (response.errors.length > 0) {
        response.errors.forEach((error) => {
          toast.error(error)
        })
      } else {
        props.onSubmit()
      }
    }
  }

  const onMemberChanged = async (e: SyntheticEvent, value: { id: string | number, name: string } | null): Promise<void> => {
    if (value == null) {
      setSelectedMember(undefined)
      return
    }

    const selectedPerson = await GetUserDetails(value?.id as string)
    setSelectedMember(selectedPerson)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    setMemberIsLead(checked)
  }

  return <Modal
    title={props.editMode ? 'Edit Team Member' : 'Add Team Member'}
    open={true}
    maxWidth='md'
    onClose={props.closeModal}
    onConfirm={handleSubmit}
    buttonClassName='modal-button'
    confirmButtonText='Save Changes'
    confirmButtonClassName='modal-confirm-button'
    cancelButtonText='Cancel'
    noButtons={props.editMode}
    bodyContent={ isLoading
      ? <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}><CircularProgress /></Box>
      : <>
        <Box sx={{ marginBottom: '.5em', marginTop: '.5em' }}>
          <MUIAutoComplete
            label='Select Member'
            name='memberSelect'
            value={selectedMember != null ? { id: selectedMember.id, name: `${selectedMember.firstName} ${selectedMember.lastName}` } : undefined }
            onChange={onMemberChanged}
            options={memberOptions}
            sx={{ width: '100%', marginBottom: '1em' }}
          />
          <UserDetails
            maxWidth='100%'
            imageKey={selectedMember?.imageKey}
            detailName={selectedMember?.firstName ?? ''}
            isOrganizationView={true}
            infoDisplay={'Member Information'}
            users={selectedMember === undefined ? [] : [selectedMember]}
            isEditable={false}
            onUpdate={() => {}}
          />
          <Box display='flex' alignItems='center' sx={{ marginTop: '1em', marginBottom: '1em' }}>
            <SquareSwitch
              name={'isLeadSwitch'}
              checked={memberIsLead}
              onChange={handleChange}
              data-testid={'memberIsLeadSwitch'}
            />
            <Typography variant='body1' sx={{ ml: '1em', fontWeight: 600 }}>CETA Lead For This Classroom</Typography>
          </Box>
        </Box>
        {
          props.editMode
            ? <Box sx={{ marginTop: '1em', width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <BlockButton onClick={async () => { await removeAccess() }} dataTestId='removeButton' hoverColor='white' backgroundColor='#BF0649' hoverBackgroundColor='#BF0649' color='white' >Remove Access</BlockButton>
              <BlockButton backgroundColor='white' onClick={props.closeModal} dataTestId='closeButton'>Cancel</BlockButton>
              <BlueButton onClick={async () => { await updateMember() }} dataTestId='updateButton'>Save Changes</BlueButton>
            </Box>
            : <></>
        }
        {showDeleteConfirmation &&
          <BannerModal
            title='Confirm Delete'
            cancelButtonText='Cancel'
            confirmButtonText='Delete Team Member'
            bodyContent={<Typography>This will remove the team member from this classroom.  Their responses to archived snapshots will be preserved but any outstanding instances will be discarded.  Do you want to continue?</Typography>}
            dataTestId='confirm-delete-modal'
            onClose={() => { setShowDeleteConfirmation(false) }}
            onConfirm={handleConfirmDelete}
          />}
      </>
    }
  />
}
