import { Slide, type SxProps } from '@mui/material'
import { type TransitionProps } from '@mui/material/transitions'
import { forwardRef } from 'react'
import { Modal } from './Modal'

interface Props {
  title?: string
  titleContent?: JSX.Element
  bodyContent?: React.ReactNode
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined
  cancelButtonText?: string
  confirmButtonText?: string
  confirmButtonColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
  hideCancelButton?: boolean
  isUpdateDisabled?: boolean
  className?: string
  buttonClassName?: string
  confirmButtonClassName?: string
  hideXtoClose?: boolean
  noButtons?: boolean
  contentSx?: SxProps
  dataTestId?: string
  onClose: () => void
  onCancel?: () => void
  onConfirm: () => void
}

const Transition = forwardRef(function Transition (
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" {...props} />
})

export const BannerModal = (props: Props): JSX.Element => {
  return <Modal
    disablePortal={true}
    open={true}
    title={props.title}
    titleContent={props.titleContent}
    bodyContent={props.bodyContent}
    contentSx={props.contentSx}
    maxWidth={props.maxWidth ?? 'xl'}
    width='100%'
    cancelButtonText={props.cancelButtonText}
    confirmButtonText={props.confirmButtonText}
    hideCancelButton={props.hideCancelButton}
    isUpdateDisabled={props.isUpdateDisabled}
    buttonClassName={props.buttonClassName}
    confirmButtonClassName={props.confirmButtonClassName}
    hideXtoClose={props.hideXtoClose ?? true}
    centerTitle={true}
    TransitionComponent={Transition}
    noButtons={props.noButtons}
    data-testid={props.dataTestId}
    position='absolute'
    containerPosition='absolute'
    bottom={0}
    margin={0}
    onClose={props.onClose}
    onConfirm={props.onConfirm}
  />
}
