import { useEffect, useState } from 'react'
import { Box, Card, CircularProgress, Grid, Link, type SxProps, Typography } from '@mui/material'
import { GetPlanOverview, GetTemplateInstructions } from '../../../../services/CETAClassroomService'
import type { PlanOverviewSection } from '../../../../models/PlanOverviewSection'
import { IconWithLabel } from '../../../customComponents/IconWithLabel'
import { BlockButton } from '../../../customComponents/buttons/BlockButton'
import { CETAPlanSection } from './CETAPlanSection'
import { CETAInstructionsKey } from '../../../../models/CETATemplateInstructions'
import { generatePath, useNavigate } from 'react-router-dom'
import { AppRoutes } from '../../../Routes'
import { BlueButton } from '../../../customComponents/buttons/BlueButton'
import { toast } from 'react-toastify'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DownloadIcon from '@mui/icons-material/Download'
import type { PlanOverviewSectionCardModel } from '../../../../models/PlanOverviewSectionCardModel'

interface Props {
  classroomId: string
  teacherName: string
  viewingPlanDetails: boolean
  onFinalizeSectionPlan: () => void
  setViewingPlanDetails: (viewingPlanDetails: boolean) => void
}

export const CETAPlanOverview = (props: Props): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true)
  const [sections, setSections] = useState<PlanOverviewSectionCardModel[]>([])
  const [planningInstructions, setPlanningInstructions] = useState('')
  const [selectedSectionId, setSelectedSectionId] = useState<number | null>(null)
  const [isSelectingSections, setIsSelectingSections] = useState<boolean>(false)
  const nav = useNavigate()

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setIsLoading(true)
      await Promise.all([
        fetchSections(),
        fetchInstructions()
      ])
      setIsLoading(false)
    }

    void fetchData()
  }, [])

  const fetchSections = async (): Promise<void> => {
    const planOverviewResponse = await GetPlanOverview(props.classroomId)
    const cardModel: PlanOverviewSectionCardModel[] = []
    planOverviewResponse.forEach(por => cardModel.push({ section: por, isSelected: false }))
    setSections(cardModel)
  }

  const fetchInstructions = async (): Promise<void> => {
    const instructionResponse = await GetTemplateInstructions(props.classroomId)
    setPlanningInstructions(instructionResponse.planning)
  }

  if (isLoading) {
    return <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}><CircularProgress /></Box>
  }

  function getSectionContent (section: PlanOverviewSection): JSX.Element {
    if (section.indicatorGoalsCreated > 0 || section.sectionGoalsCreated > 0) {
      if (section.sectionGoalsCreated === 0) {
        return <IconWithLabel sx={{ marginBottom: '2em' }} icon={<CheckCircleOutlineIcon />} labelText={`Indicator Goal Created (${section.indicatorGoalsCreated})`} dataTestId={`${section.name}-indicators`} />
      } else {
        return <>
          <IconWithLabel sx={{ marginBottom: '.5em' }} icon={<CheckCircleOutlineIcon />} labelText={`Indicator Goal Created (${section.indicatorGoalsCreated})`} dataTestId={`${section.name}-indicators`} />
          <IconWithLabel sx={{ marginBottom: '2em' }} icon={<CheckCircleIcon />} labelText='Section Goal Created' dataTestId={`${section.name}-sections`} />
        </>
      }
    } else {
      return <>
        <IconWithLabel sx={{ marginBottom: '.5em' }} icon={<AddCircleOutlineIcon />} labelText={`Strengths (${section.strengths})`} dataTestId={`${section.name}-strengths`} />
        <IconWithLabel sx={{ marginBottom: '2em' }} icon={<ErrorOutlineIcon />} labelText={`Consider As Priority (${section.considerPriority})`} dataTestId={`${section.name}-priorities`} />
      </>
    }
  }

  function getCardContent (model: PlanOverviewSectionCardModel, sectionIndex: number): JSX.Element {
    const unselectedCss: SxProps = { position: 'relative', width: '270px', height: '270px', borderRadius: '16px', backgroundColor: `${sectionIndex % 2 === 0 ? 'rgb(33, 174, 222, .3)' : 'rgb(124, 127, 189, .3)'}` }

    const cardDetails = <Card sx={unselectedCss} data-testid={`${model.section.id}card`}>
      <Box sx={{ margin: '1em' }}>
        <Typography sx={{ fontWeight: 600, fontSize: '1.25em' }}>{model.section.name}</Typography>
        <Box sx={{ display: 'flex', mt: '2em' }}>
          <Box>
            {getSectionContent(model.section)}
          </Box>
        </Box>
        <Box sx={{ marginTop: '3em' }}>
          <BlockButton disabled={isSelectingSections} sx={{ position: 'absolute', bottom: 0, marginBottom: '1em' }} backgroundColor='rgb(255, 255, 255, .5)' onClick={() => { handleViewDetails(model.section) }} dataTestId={model.section.name + '-detailsButton'}>
            View Section Details
          </BlockButton>
        </Box>
      </Box>
    </Card>

    const circleIcon = <AddCircleIcon data-test-id={`${model.section.id}addIcon`} sx={{ position: 'relative', left: 0, top: 20, zIndex: 1000, backgroundColor: 'white', borderRadius: '16px', color: '#004A70' }}/>
    const errorIcon = <ErrorIcon data-test-id={`${model.section.id}errorIcon`} sx={{ position: 'relative', left: 0, top: 20, zIndex: 1000, backgroundColor: 'white', borderRadius: '16px', color: '#004A70' }}/>
    const checkCircleIcon = <CheckCircleIcon data-test-id={`${model.section.id}checkIcon`} sx={{ position: 'relative', left: 0, top: 20, zIndex: 1000, backgroundColor: 'white', borderRadius: '16px', color: '#004A70' }}/>

    if (!isSelectingSections) {
      return cardDetails
    } else {
      const hasGoals = model.section.sectionGoalsCreated > 0 || model.section.indicatorGoalsCreated > 0

      return <div>{!hasGoals ? errorIcon : model.isSelected ? checkCircleIcon : circleIcon}
        <div id={`${model.section.id}card-div`} className={model.isSelected ? 'clicked-div' : 'clickable-card'}>
          <Link sx={{ cursor: 'pointer', color: '#156ABC', overlay: 'auto' }} onClick={() => { addSectionToPrint(model.section.id, hasGoals) }} data-testid={`${model.section.id}cardLink`}>{cardDetails}</Link>
        </div>
      </div>
    }
  }

  const handleViewDetails = (section: PlanOverviewSection): void => {
    props.setViewingPlanDetails(true)
    setSelectedSectionId(section.id)
  }

  const handleBack = (): void => {
    props.setViewingPlanDetails(false)
    setSelectedSectionId(null)
  }

  function addSectionToPrint (id: number, hasGoals: boolean): void {
    if (!hasGoals) {
      toast.error('This section does not have any goals associated with it.')
      return
    }

    const copiedSections: PlanOverviewSectionCardModel[] = [...sections]

    copiedSections.forEach(s => {
      if (s.section.id === id) {
        s.isSelected = !s.isSelected
      }
    })

    setSections(copiedSections)
  }

  function printSelection (): void {
    if (sections.find(s => s.isSelected) == null) {
      toast.error('No sections are selected.')
      return
    }
    const sectionsToPrint: string[] = sections.filter((s) => s.isSelected).map(s => s.section.id.toString())
    const printRoute = generatePath(AppRoutes.CETA_SECTION_PRINT, { classroomId: props.classroomId, sectionIds: sectionsToPrint.join(',') })
    nav(printRoute)
  }

  return <>
    {!props.viewingPlanDetails &&
      <Box>
        <Grid container spacing={0} sx={{ mt: '2em' }}>
          <Grid item sx={{ width: '65%' }}>
            <Box sx={{ display: 'flex', marginLeft: '2em', justifyContent: 'space-between' }}>
              <Typography variant='h5'>Sections</Typography>
              {isSelectingSections
                ? <Box sx={{ alignSelf: 'end' }}>
                  <BlueButton onClick={() => { setIsSelectingSections(false) }} sx={{ marginLeft: '1em', justifyContent: 'space-between' }} dataTestId='cancelSelection'>
                    Cancel
                  </BlueButton>
                  <BlueButton onClick={printSelection} sx={{ marginLeft: '1em', justifyContent: 'space-between' }} dataTestId='downloadGoals'>
                    Download Selected Sections
                    <DownloadIcon />
                  </BlueButton>
                </Box>
                : <BlueButton onClick={() => { setIsSelectingSections(true) }} sx={{ marginLeft: '1em', justifyContent: 'space-between' }} dataTestId='setSections'>
                  Select sections for Action Plan
                  <DownloadIcon />
                </BlueButton>
                }
            </Box>
            {isSelectingSections && <Typography sx={{ marginLeft: '2em' }} variant='body1'>Select the sections you'd like to generate an action plan for</Typography>
          }
          </Grid>
          <Grid container item spacing={2} sx={{ width: '65%', marginLeft: '2em', marginTop: '1em' }}>
            { (sections != null && sections.length > 0) && sections.map((model, sectionIndex) => (
              <Grid item key={model.section.name + '-grid'}>
                {
                  getCardContent(model, sectionIndex)
                }
              </Grid>
            ))}
          </Grid>

          <Grid item>
            <Card
              sx={{
                backgroundColor: '#FAFEFF',
                maxWidth: '300px',
                border: '1px solid #21AEDE',
                p: '1em'
              }}
            >
              <Typography variant='h5' color={'#004A70'}>
                {CETAInstructionsKey.PlanningInstructions}
              </Typography>

              <div className='ql-editor' dangerouslySetInnerHTML={{ __html: planningInstructions }} />

              <Typography variant='body1' fontWeight='600' sx={{ marginBottom: '1em', marginTop: '1em' }}>Icon Legend</Typography>
              <IconWithLabel sx={{ marginBottom: '.5em' }} icon={<CheckCircleOutlineIcon />} labelText='Goal Created - Indicator' />
              <IconWithLabel sx={{ marginBottom: '.5em' }} icon={<CheckCircleIcon />} labelText='Goal Created - Section' />
              <IconWithLabel sx={{ marginBottom: '.5em' }} icon={<ErrorOutlineIcon />} labelText='Consider As Priority' />
              <IconWithLabel sx={{ marginBottom: '.5em' }} icon={<AddCircleOutlineIcon />} labelText='Strength' />
            </Card>
          </Grid>
        </Grid>
      </Box>
    }
    {props.viewingPlanDetails && selectedSectionId != null &&
      <CETAPlanSection classroomId={props.classroomId} sectionId={selectedSectionId} goBack={handleBack} onFinalize={props.onFinalizeSectionPlan} refetchOverviewSections={fetchSections} />
    }
  </>
}
