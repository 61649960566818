export interface CETATemplateInstructions {
  templateId: number
  aboutTheCETA: string
  cetaBenefits: string
  recommendedCollabForCETA: string
  implementingCETA: string
  codingSystem: string
  reconcile: string
  planning: string
  progressUpdate: string
}

export enum CETAInstructionsKey {
  AboutTheCETA = 'About The CETA',
  CETABenefits = 'Benefits of the CETA',
  RecommendedCollabForCETA = 'Recommended Collaborative Process for the CETA',
  ImplementingCETA = 'Using the START Data System to Implement the CETA',
  CETACodingSystem = 'Coding System',
  ReconcileInstructions = 'Finalize Instructions',
  PlanningInstructions = 'Planning Instructions',
  ProgressUpdateInstructions = 'Progress Update Instructions'
}
