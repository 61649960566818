import { Box, Typography } from '@mui/material'
import { EventDisplay } from '../../../customComponents/EventDisplay'
import { ProfileImage } from '../../../customComponents/user/ProfileImage'
import { type CETAGoal } from '../../../../models/CETAPlanSectionModel'
import { CETAGoalActionDetails } from './CETAGoalActionDetails'

interface Props {
  goal: CETAGoal | undefined
}

export const CETAGoalDetails = (props: Props): JSX.Element => {
  return <>
    {props.goal != null &&
      <Box data-testid='goalDetails'>
        <Box display='flex' alignItems='center'>
          <EventDisplay event={{ title: 'Target Date', date: props.goal.targetGoalDate }} />
          <ProfileImage name={props.goal.owner.name} imageKey={props.goal.owner.imageKey} sx={{ ml: '1em' }} />
          <Typography ml='.5em'>{props.goal.owner.name}</Typography>
        </Box>

        <Typography mt='1em'>{props.goal.summary}</Typography>

        <Typography mt='1em' mb='.5em' fontWeight='600'>Actions</Typography>

        {props.goal.actions.map((action, index) => (
          <CETAGoalActionDetails key={index} action={action} />
        ))}

        {props.goal.actions.length === 0 && <Typography>No actions have been added</Typography>}
      </Box>
    }
  </>
}
