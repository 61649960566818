import { ArrowForward, KeyboardArrowDown, SportsFootballOutlined } from '@mui/icons-material'
import { Card, Typography, Accordion, AccordionSummary, Box, AccordionDetails } from '@mui/material'
import { BlueButton } from '../../../customComponents/buttons/BlueButton'

interface Props {
  showViewPlan: boolean
  showContinuePlan: boolean
  showArchivePlan: boolean
  viewPlan: () => void
  continuePlan: () => void
  archivePlan: () => void
}

export const PlanActionCard = (props: Props): JSX.Element => {
  return <Card sx={{ backgroundColor: '#F3F3F380', minHeight: '3em', width: '300px', border: '1px solid #CBC8CC', p: '1em' }}>
    <Accordion data-testid='actionAccordion'>
      <AccordionSummary expandIcon={<KeyboardArrowDown sx={{ color: 'white', bgcolor: '#726F74', width: '1.125em', height: '1.125em', borderRadius: '50%' }} data-testid='expand' />}>
        <Box sx={{ display: 'flex' }}>
          <SportsFootballOutlined />
          <Typography variant='body1' textAlign='center' ml='1em'>
            Plan Actions
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          { props.showViewPlan &&
            <BlueButton onClick={props.viewPlan} sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
              View Plan
              <ArrowForward />
            </BlueButton>
          }
          { props.showContinuePlan &&
            <BlueButton onClick={props.continuePlan} sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
              Continue Plan
              <ArrowForward />
            </BlueButton>
          }
          { props.showArchivePlan &&
            <BlueButton onClick={props.archivePlan} sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
              Archive Plan
              <ArrowForward />
            </BlueButton>
          }
        </Box>
      </AccordionDetails>
    </Accordion>
  </Card>
}
