import { ArrowBack, KeyboardArrowDown } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Box, Breadcrumbs, Card, CircularProgress, Grid, Link, Typography } from '@mui/material'
import { generatePath, useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import { AppRoutes } from '../../Routes'
import { useEffect, useState } from 'react'
import { FinalizeSnapshot, GetClassroomDetails, GetReconcileAssessment, GetTemplateInstructions } from '../../../services/CETAClassroomService'
import { type CETAReconcileAssessmentSection, type CETAReconcileAssessment } from '../../../models/CETAReconcileAssessment'
import { ResourceLayers } from '../../../assets/svgs/ResourceLayers'
import { useAuth } from '../../../hooks/use-auth'
import { IndicatorReview } from './IndicatorReview'
import { type CETAReconcileIndicatorRequest } from '../../../models/CETAReconcileIndicatorRequest'
import { BlueButton } from '../../customComponents/buttons/BlueButton'
import { toast } from 'react-toastify'
import { Modal } from '../../customComponents/Modal'
import { CETAOverviewTabs } from './CETAOverview'
import { CETAInstructionsKey } from '../../../models/CETATemplateInstructions'
import { BlockButton } from '../../customComponents/buttons/BlockButton'

export const CETAReconcile = (): JSX.Element => {
  const { classroomId, snapshotId } = useParams()
  const nav = useNavigate()
  const { user } = useAuth()

  const [teacherName, setTeacherName] = useState<string | null>(null)
  const [reconcileInstructions, setReconcileInstructions] = useState<string | null>(null)
  const [reconcileAssessment, setReconcileAssessment] = useState<CETAReconcileAssessment | null>(null)
  const [readOnly, setReadOnly] = useState<boolean>(true)
  const [selectedSection, setSelectedSection] = useState<number | null>(null)
  const [selectedIndicator, setSelectedIndicator] = useState<number | null>(null)
  const [isFinalizing, setIsFinalizing] = useState<boolean>(false)
  const [highlightIndicators, setHighlightIndicators] = useState<boolean>(false)

  const fetchData = async (): Promise<void> => {
    if (classroomId == null || snapshotId == null) {
      return
    }

    const results = await Promise.all([
      GetClassroomDetails(classroomId),
      GetTemplateInstructions(classroomId),
      GetReconcileAssessment(snapshotId)
    ])

    setTeacherName(results[0].teacherName)
    setReconcileInstructions(results[1].reconcile)
    setReconcileAssessment(results[2])
    if (user != null) {
      setReadOnly(results[2].snapshotCompleted || !results[2].classroomLeads.includes(user.id))
    }
  }

  useEffect(() => {
    void fetchData()
  }, [classroomId, snapshotId, user])

  const goBack = (): void => {
    const route = generatePath(AppRoutes.CETA_OVERVIEW, { classroomId: classroomId ?? '', tab: CETAOverviewTabs.Dashboard })
    nav(route)
  }

  const onSaveIndicatorRating = async (indicatorUpdate: CETAReconcileIndicatorRequest): Promise<void> => {
    if (reconcileAssessment == null) {
      return
    }

    const indicatorIndex = reconcileAssessment.sections.findIndex(s => s.indicators.some(i => i.id === indicatorUpdate.id))
    const indicator = reconcileAssessment.sections[indicatorIndex].indicators.find(i => i.id === indicatorUpdate.id)
    if (indicator == null) {
      return
    }

    indicator.rating = indicatorUpdate.rating
    indicator.notes = indicatorUpdate.notes

    const newReconcileAssessment = { ...reconcileAssessment }
    newReconcileAssessment.sections[indicatorIndex].indicators = reconcileAssessment.sections[indicatorIndex].indicators.map(i => i.id === indicator.id ? indicator : i)

    setReconcileAssessment(newReconcileAssessment)
  }

  const selectIndicator = (indicatorId: number): void => {
    if (selectedIndicator === indicatorId) {
      setSelectedIndicator(null)
    } else {
      setSelectedIndicator(indicatorId)
    }
  }

  const startFinalizing = (): void => {
    if (reconcileAssessment == null) {
      return
    }

    const allIndicatorsReconciled = reconcileAssessment.sections.every(s => s.indicators.every(i => i.rating != null || i.submittedIndicators.every(i => i.rating == null)))
    if (!allIndicatorsReconciled) {
      setHighlightIndicators(true)
      toast.error('Please confirm responses for all indicators')
      return
    }

    setIsFinalizing(true)
  }

  const finalize = async (): Promise<void> => {
    if (snapshotId == null) {
      return
    }

    await FinalizeSnapshot(snapshotId)
    setIsFinalizing(false)
    toast.success('Snapshot finalized')
    goBack()
  }

  const skippedSection = (section: CETAReconcileAssessmentSection): boolean => {
    return section.indicators.every(i => i.submittedIndicators.every(si => si.rating == null))
  }

  const shouldHighlightSection = (section: CETAReconcileAssessmentSection): boolean => {
    return highlightIndicators && section.indicators.some(i => i.rating == null) && !skippedSection(section)
  }

  const assessmentSection = (section: CETAReconcileAssessmentSection, index: number): JSX.Element => {
    const skipped = skippedSection(section)

    return <Accordion
      key={index}
      data-testid={`section-${section.id}`}
      disabled={skipped}
      expanded={selectedSection === section.id}
      sx={{ mb: '.5em', borderRadius: '6px', border: shouldHighlightSection(section) ? '1px solid red' : '1px solid #CBC8CC' }}
    >
      <AccordionSummary
        onClick={() => { setSelectedSection(selectedSection === section.id ? null : section.id) }}
        sx={{ opacity: '1 !important' }}
        expandIcon={
          <KeyboardArrowDown
            data-testid='expand'
            sx={{
              color: 'white',
              bgcolor: '#726F74',
              width: '1.125em',
              height: '1.125em',
              borderRadius: '50%',
              opacity: skipped ? 0.38 : 1
            }}
          />
        }
      >
        <Box display='flex' alignItems='center'>
          <ResourceLayers width='24px' height='24px' opacity={skipped ? 0.38 : 1} />
          <Typography variant='h6' ml='.5em' color={skipped ? '#474747' : '#000000'}>
            {section.name}{skipped ? ' - No indicators to reconcile' : ''}
          </Typography>
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        {section.indicators.map((indicator, index) => (
          <IndicatorReview
            key={index}
            isSelected={selectedIndicator === indicator.id}
            indicator={indicator}
            readOnly={readOnly}
            highlightIndicators={highlightIndicators}
            onSaveIndicatorRating={onSaveIndicatorRating}
            onClick={() => { selectIndicator(indicator.id) }}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  }

  if (reconcileAssessment == null) {
    return <CircularProgress />
  }

  return <Box>
    <Breadcrumbs maxItems={1} aria-label='breadcrumb' sx={{ mb: '.5em' }}>
      <Link sx={{ cursor: 'pointer' }} underline='hover' color='inherit' onClick={goBack} data-testid='backToDashboard'>
        <ArrowBack sx={{ verticalAlign: 'middle', mr: '5px' }} />
        Back to CETA Dashboard
      </Link>
    </Breadcrumbs>

    <Typography variant='h3' className='page-header'>{(teacherName != null) ? `${teacherName}'s Classroom` : ''}</Typography>
    <Typography variant='h4' className='page-subheader' data-testid='subPageHeader' mt='.5em'>{`Finalize ${reconcileAssessment.snapshotName}`}</Typography>

    <Box mx='4em' mt='1em'>
      <Grid container spacing={4}>
        <Grid item xs={true} maxWidth='1200px'>

          {reconcileAssessment.sections.map((section, index) => (
            assessmentSection(section, index)
          ))}

          <Box>
            <BlockButton sx={{ height: '4em', width: '10em', mt: '1em' }} onClick={goBack} dataTestId='returnButton'>Return</BlockButton>
            {!readOnly &&
              <BlueButton sx={{ height: '4em', width: '10em', mt: '1em', float: 'right' }} onClick={startFinalizing} dataTestId='finalize'>Finalize</BlueButton>
            }
          </Box>
        </Grid>

        <Grid item>
          <Card
            sx={{
              backgroundColor: '#FAFEFF',
              minHeight: '3em',
              maxWidth: '300px',
              border: '1px solid #21AEDE',
              p: '1em'
            }}
          >
            <Typography variant='h5' color='#004A70'>
              {CETAInstructionsKey.ReconcileInstructions}
            </Typography>

            <div className='ql-editor' dangerouslySetInnerHTML={{ __html: reconcileInstructions ?? '' }} data-testid='reconcileInstructions-text' />
          </Card>
        </Grid>
      </Grid>
    </Box>

    {isFinalizing &&
      <Modal
        open={true}
        title='Finalize Snapshot'
        cancelButtonText='No, Cancel'
        confirmButtonText='Yes, Finalize'
        bodyContent={'By clicking \'Yes, Finalize\', your CETA Reconciliation will be finalized. You will not be able to provide further edits to the CETA Reconciliation.'}
        onClose={() => { setIsFinalizing(false) }}
        onConfirm={finalize}
      />
    }
  </Box>
}
