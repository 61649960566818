export interface OnboardingTask {
  key: string
  name: string
  completed: boolean
  manual: boolean
  locked: boolean
}

export enum OnboardingTaskKey {
  AdminContacts = 'AdminContacts',
  ISDsOnboarded = 'ISDsOnboarded',
  DistrictsOnboarded = 'DistrictsOnboarded',
  MiDXConnectivity = 'MiDXConnectionVerified',
  TicketCreated = 'TicketCreated',
  AgreementSigned = 'AgreementSigned',
  EEMReportsValidated = 'EEMReportsValidated',
  ReadinessCheckReviewed = 'ReadinessCheckReviewed',
  SyncEnabled = 'SyncEnabled',
  ReviewPopulationReport = 'ReviewPopulationReport',
  StartCETAKickoff = 'StartCETAKickoff',
  CompleteCETAKickoff = 'CompleteCETAKickoff',
  CreateAPlan = 'CreateAPlan',
  MonitorProgress = 'MonitorProgress',
  CompleteCETAUpdate = 'CompleteCETAUpdate',
  ContinueOrArchive = 'ContinueOrArchive'
}
