import { type Response, sendGet, sendPost, sendPostFormData } from '../hooks/use-fetch'
import type { UserSearchValues } from '../pages/customComponents/user/Search'
import type { User } from '../models/User'
import type { UserInfoFormValues } from '../pages/customComponents/user/DetailsEdit'
import { type SelectableOrganization } from '../models/SelectableOrganization'
import type { Subscription } from '../models/Subscription'
import { type SelectableOption } from '../utils/genericTypes'
import { type AddContactForm } from '../pages/pageComponents/organizations/users/AddContactModal'
import { type NewUserFormValues } from '../pages/pageComponents/organizations/users/AddUserModal'
import { type OrganizationAccessRequest } from '../models/OrganizationAccessRequest'

export async function GetBuildingOptionsForOrg (orgId: number): Promise<SelectableOption[]> {
  const request = await sendGet(`UserManagement/GetBuildingOptionsForOrg?orgId=${orgId}`)
  const buildings: SelectableOption[] = request.response
  return buildings
}

export async function GetOrganizationOptionsForUser (): Promise<SelectableOrganization[]> {
  const request = await sendGet('UserManagement/GetOrganizationOptions')
  const organizations: SelectableOrganization[] = request.response
  return organizations
}

export async function GetUserOptionsForOrg (orgId: number): Promise<SelectableOption[]> {
  const request = await sendGet(`UserManagement/GetUserOptionsForOrg?orgId=${orgId}`)
  const users: SelectableOption[] = request.response
  return users
}

export async function GetUsersFromSearchCriteria (searchValues: UserSearchValues): Promise<User[]> {
  const request = await sendPost('UserManagement/GetUsersFromSearchCriteria', searchValues)
  const users: User[] = request.response
  return users
}

export async function GetUserDetails (userId: string): Promise<User> {
  const request = await sendGet(`UserManagement/GetUserDetails?userId=${userId}`)
  const user: User = request.response
  return user
}

export async function GetAccessRequest (userId: string, orgId: number): Promise<OrganizationAccessRequest> {
  const { response } = await sendGet(`UserManagement/GetAccessRequest?userId=${userId}&orgId=${orgId}`)
  return response
}

export async function ApproveAccessRequest (accessRequestId: string, roleName: string, functions: string[], note: string): Promise<void> {
  await sendPost('UserManagement/ApproveAccessRequest', { accessRequestId, roleName, functions, note })
}

export async function DenyAccessRequest (accessRequestId: string, note: string): Promise<Response> {
  return await sendPost('UserManagement/DenyAccessRequest', { accessRequestId, note })
}

export async function SetDefaultOrganization (userId: string, organizationId: number): Promise<void> {
  await sendPost('UserManagement/SetDefaultOrganization', { userId, organizationId })
}

export async function AddUser (user: NewUserFormValues): Promise<Response> {
  return await sendPost('UserManagement/AddUser', user)
}

export async function UpdateUser (user: UserInfoFormValues): Promise<Response> {
  return await sendPost('UserManagement/UpdateUser', user)
}

export async function AddAdminContact (contact: AddContactForm): Promise<Response> {
  return await sendPost('UserManagement/AddAdminContact', contact)
}

export async function RemoveAdminContact (userId: number, organizationId: number): Promise<void> {
  await sendPost('UserManagement/RemoveAdminContact', { userId, organizationId })
}

export async function UploadProfileImage (userId: string, file: File): Promise<string> {
  const fileData = new FormData()
  fileData.append('file', file)
  fileData.append('userId', userId)
  const { response: s3key } = await sendPostFormData('UserManagement/UploadProfileImage', fileData)
  return s3key
}

export async function UpdateUserSubscriptions (subscriptions: Subscription[]): Promise<boolean> {
  const response = await sendPost('UserManagement/UpdateUserSubscriptionPreferences', subscriptions)
  return response.success
}

export async function UpdateStatus (userId: string, isActive: boolean): Promise<void> {
  await sendPost('UserManagement/UpdateStatus', { userId, isActive })
}

export async function UpdateUserBuildings (userId: string, orgId: string, buildingIds: number[]): Promise<void> {
  await sendPost('UserManagement/UpdateUserBuildings', { userId, orgId, buildingIds })
}
