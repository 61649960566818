import { Box, Breadcrumbs, CircularProgress, Link, Tab, Tabs, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { AppRoutes } from '../../Routes'
import { TabDisplay } from '@oaisd/michdev.components.react'
import { CETADashboard } from './Dashboard/CETADashboard'
import { CETAClassGettingStarted } from './CETAClassGettingStarted'
import { GetClassroomDetails, GetNameAndTeamMemberInfo, SetUserStartedCeta } from '../../../services/CETAClassroomService'
import LockIcon from '@mui/icons-material/Lock'
import type { CETATeamMember } from '../../../models/CETATeamMember'
import { generatePath } from 'react-router'
import { CETAPlanOverview } from './Plan/CETAPlanOverview'
import { CETAClassroomStage } from '../../../models/CETAClassroomStage'
import type { AvailableCETAProcesses } from '../../../models/AvailableCETAProcesses'
import { GetAvailableCETAProcesses } from '../../../utils/cetaUtils'

export class CETAOverviewTabs {
  static readonly GettingStarted = '0'
  static readonly Dashboard = '1'
  static readonly Plan = '2'
  static readonly ProgressUpdates = '3'
  static readonly ProgressReport = '4'
  static readonly Resources = '5'
}

export interface CetaTeamMemberAndTeacherName {
  teacherName: string
  teamMember: CETATeamMember
}

export function CETAOverview (): JSX.Element {
  const nav = useNavigate()
  const { classroomId, tab: tabString } = useParams()
  const tab = parseInt(tabString ?? '0')

  const [timeSpanId, setTimeSpanId] = useState<number | null>(null)
  const [teacherName, setTeacherName] = useState<string | null>(null)
  const [teamMember, setTeamMember] = useState<CETATeamMember | null>(null)
  const [planLocked, setPlanLocked] = useState<boolean>(false)
  const [progressUpdateLocked, setProgressUpdateLocked] = useState<boolean>(false)
  const [progressReportLocked, setProgressReportLocked] = useState<boolean>(false)
  const [selectedTab, setSelectedTab] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [viewingPlanDetails, setViewingPlanDetails] = useState(false)
  const [statusCanceled, setStatusCanceled] = useState<boolean>(false)
  const [availableProcesses, setAvailableProcesses] = useState<AvailableCETAProcesses>()

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (classroomId == null) {
        return
      }

      const classroom = await GetClassroomDetails(classroomId)
      const processesAvail = GetAvailableCETAProcesses(classroom.status, classroom.stage)
      setPlanLocked(classroom.planLocked)
      setProgressUpdateLocked(classroom.progressUpdateLocked || processesAvail.progressUpdatesTabLocked)
      setProgressReportLocked(classroom.progressReportLocked || processesAvail.progressUpdatesTabLocked)
      setAvailableProcesses(processesAvail)
      setTimeSpanId(classroom.timeSpanId)
      setStatusCanceled(classroom.stage === CETAClassroomStage.InvitationDeclined)
      const info: CetaTeamMemberAndTeacherName = await GetNameAndTeamMemberInfo(classroomId)

      let defaultTab = tab
      if (info != null) {
        setTeacherName(info.teacherName)
        setTeamMember(info.teamMember)
        const started = info.teamMember?.hasStarted ?? false
        if (started && tab === Number(CETAOverviewTabs.GettingStarted)) {
          defaultTab = Number(CETAOverviewTabs.Dashboard)
        }
      }

      setSelectedTab(defaultTab)
      setIsLoading(false)
    }

    void fetchData()
  }, [classroomId, tab])

  const goBack = (): void => {
    if (viewingPlanDetails) {
      setViewingPlanDetails(false)
    } else {
      const route = generatePath(AppRoutes.CETA_CLASSROOMS, { timeSpanId: timeSpanId?.toString() ?? '', classroomId: null })
      nav(route)
    }
  }

  const getStarted = async (): Promise<void> => {
    if (classroomId != null) {
      await SetUserStartedCeta(classroomId)
      if (teamMember != null) {
        const updatedMember = { ...teamMember }
        updatedMember.hasStarted = true
        setTeamMember(updatedMember)
      }
      setSelectedTab(Number(CETAOverviewTabs.Dashboard))
    }
  }

  const updateTab = (tab: number): void => {
    setSelectedTab(tab)
    setViewingPlanDetails(false)
  }

  const handlePlanSectionFinalized = async (): Promise<void> => {
    if (classroomId == null) {
      return
    }
    const classroom = await GetClassroomDetails(classroomId)
    const processesAvail = GetAvailableCETAProcesses(classroom.status, classroom.stage)
    setPlanLocked(classroom.planLocked)
    setProgressUpdateLocked(classroom.progressUpdateLocked || processesAvail.progressUpdatesTabLocked)
    setProgressReportLocked(classroom.progressReportLocked || processesAvail.progressUpdatesTabLocked)
  }

  const getLockedLabel = (tabName: string): JSX.Element => {
    return <Box sx={{ display: 'flex' }}><>{tabName}<LockIcon sx={{ marginLeft: '.5em', fontSize: '.875rem' }}/></></Box>
  }

  const hasStarted = teamMember?.hasStarted ?? false

  if (classroomId == null || isLoading) {
    return <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}><CircularProgress /></Box>
  }

  if (teacherName == null) {
    return <Box>Classroom Not Found</Box>
  }

  return <Box>
    <Breadcrumbs maxItems={1} aria-label="breadcrumb" sx={{ mb: '.5em' }}>
      <Link sx={{ cursor: 'pointer' }} underline="hover" color="inherit" onClick={goBack} data-testid='backToClassrooms'>
        <ArrowBackIcon sx={{ verticalAlign: 'middle', mr: '5px' }} />
        Back to {viewingPlanDetails ? 'Sections' : 'CETA Classrooms'}
      </Link>
    </Breadcrumbs>

    <Typography variant='h3' className='page-header'>{teacherName}</Typography>
    <Typography variant='h4' className='page-subheader' data-testid='subPageHeader' mt='.5em'>CETA</Typography>

    <Tabs value={selectedTab} onChange={(e, val) => { updateTab(val) }} sx={{ mb: '2em', mt: '1em' }}>
      <Tab label='Getting Started' value={0} sx={{ textTransform: 'none' }} data-testid='gettingStartedTab' />
      <Tab disabled={!hasStarted} label={hasStarted ? 'Dashboard' : getLockedLabel('Dashboard')} value={1} sx={{ textTransform: 'none' }} data-testid='dashboardTab' />
      <Tab disabled={planLocked} label={!planLocked ? 'Plan' : getLockedLabel('Plan')} value={2} sx={{ textTransform: 'none' }} data-testid='planTab' />
      <Tab disabled={progressUpdateLocked} label={!progressUpdateLocked ? 'Progress Updates (Coming Soon)' : getLockedLabel('Progress Updates (Coming Soon)')} value={3} sx={{ textTransform: 'none' }} data-testid='progressUpdatesSTab' />
      <Tab disabled={progressReportLocked} label={!progressReportLocked ? 'Progress Report (Coming Soon)' : getLockedLabel('Progress Report (Coming Soon)')} value={4} sx={{ textTransform: 'none' }} data-testid='progressReportTab' />
      <Tab label='Resources (Coming Soon)' value={5} sx={{ textTransform: 'none' }} data-testid='resourcesTab' />
    </Tabs>

    <TabDisplay index={0} value={selectedTab}>
      <CETAClassGettingStarted readonly={statusCanceled} startClicked={getStarted} hasStarted={teamMember?.hasStarted ?? false}></CETAClassGettingStarted>
    </TabDisplay>
    <TabDisplay index={1} value={selectedTab}>
      <CETADashboard planLocked={availableProcesses?.planningTabLocked ?? false} snapshotsReadonly={availableProcesses?.snapshotsReadonly ?? false} classroomId={classroomId} />
    </TabDisplay>
    <TabDisplay index={2} value={selectedTab}>
      <CETAPlanOverview teacherName={(teacherName != null) ? teacherName : ''} classroomId={classroomId} viewingPlanDetails={viewingPlanDetails} setViewingPlanDetails={setViewingPlanDetails} onFinalizeSectionPlan={handlePlanSectionFinalized} />
    </TabDisplay>
    <TabDisplay index={3} value={selectedTab}>
    </TabDisplay>
    <TabDisplay index={4} value={selectedTab}>
    </TabDisplay>
    <TabDisplay index={5} value={selectedTab}>
    </TabDisplay>
  </Box>
}
