import { type AuthUser } from '../hooks/use-auth'

export class AuthFunctions {
  static readonly USER_MANAGEMENT = 'User Management'
  static readonly USER_MANAGEMENT_EDIT = 'User Management - Edit'
  static readonly IMPERSONATION = 'Impersonation'
  static readonly IMPERSONATION_EDIT = 'Impersonation - Edit'
  static readonly PROJECT_SETTINGS = 'Project Settings'
  static readonly PROJECT_SETTINGS_EDIT = 'Project Settings - Edit'
  static readonly RCN_MANAGEMENT = 'RCN Management'
  static readonly RCN_MANAGEMENT_EDIT = 'RCN Management - Edit'
  static readonly RCN_ADMINISTRATION = 'RCN Administration'
  static readonly RCN_ADMINISTRATION_EDIT = 'RCN Administration - Edit'
  static readonly ISD_MANAGEMENT = 'ISD Management'
  static readonly ISD_MANAGEMENT_EDIT = 'ISD Management - Edit'
  // static readonly CONTRACT_COLLABORATION = 'Contract Collaboration'
  // static readonly CONTRACT_COLLABORATION_EDIT = 'Contract Collaboration - Edit'
  // static readonly CONTRACT_ADMINISTRATION = 'Contract Administration'
  // static readonly CONTRACT_ADMINISTRATION_EDIT = 'Contract Administration - Edit'
  // static readonly REPORTS = 'Reports'
  // static readonly REPORTS_EDIT = 'Reports - Edit'
  static readonly ISD_ADMINISTRATION = 'ISD Administration'
  static readonly ISD_ADMINISTRATION_EDIT = 'ISD Administration - Edit'
  static readonly DISTRICT_MANAGEMENT = 'District Management'
  static readonly DISTRICT_MANAGEMENT_EDIT = 'District Management - Edit'
  static readonly DISTRICT_ADMINISTRATION = 'District Administration'
  static readonly DISTRICT_ADMINISTRATION_EDIT = 'District Administration - Edit'
  // static readonly BUILDING_ACCESS = 'Building Access'
  // static readonly BUILDING_ACCESS_EDIT = 'Building Access - Edit'
  static readonly CETA_PARTICIPATION = 'CETA Participation'
  static readonly CETA_PARTICIPATION_EDIT = 'CETA Participation - Edit'
  static readonly CETA_TEACHER = 'CETA Teacher'
  static readonly CETA_TEACHER_EDIT = 'CETA Teacher - Edit'
  static readonly CETA_COACH = 'CETA Coach'
  static readonly CETA_COACH_EDIT = 'CETA Coach - Edit'
  // static readonly FOCUS_STUDENT_MANAGEMENT = 'Focus Student Management'
  // static readonly FOCUS_STUDENT_MANAGEMENT_EDIT = 'Focus Student Management - Edit'
  // static readonly FOCUS_STUDENT_IMPLEMENTATION = 'Focus Student Implementation'
  // static readonly FOCUS_STUDENT_IMPLEMENTATION_EDIT = 'Focus Student Implementation - Edit'
}

export const hasPermission = (user: AuthUser, permission: string): boolean => {
  return user.userFunctions.includes(permission)
}
